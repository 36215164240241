<template>
    <div>
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
            :header-color="'var(--v-fileArchiveOrange-base)'"
        />
        <label
            style="display: none"
            for="hausiPupilUploadInput"
            hidden
        >
            Aufgaben hochladen
        </label>
        <input
            id="hausiPupilUploadInput"
            ref="hausiPupilUploadInput"
            type="file"
            hidden
            @change="() => pupilUploadInputChange(item.appointmentId, item._id)"
        >
        <div>
            <v-row
                v-if="false"
                class="px-3"
            >
                <v-col
                    class="px-1"
                    cols="4"
                >
                    <v-tooltip
                        :value="shortcutTooltips"
                        :disabled="!showTooltips"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-badge
                                color="#ff0000"
                                :content="todoUnseenCount"
                                :value="todoUnseenCount"
                                overlap
                                left
                                offset-x="10"
                                style="z-index: 8; width: 100%; height: 48px !important;"
                            >
                                <v-btn
                                    :id="'toDoTab'"
                                    :color="tab === 'toDo' ? '#e6231e' : 'white'"
                                    :dark="tab === 'toDo'"
                                    style="width: 100%; border: solid 1px #e6231e !important; overflow: hidden; text-overflow: ellipsis"
                                    elevation="0"
                                    role="tab"
                                    class="text-capitalize"
                                    @click="tab = 'toDo'; checkImagePreviews(); setFocusOn('toDoTab')"
                                    v-on="on"
                                    @keydown.tab="setFocusOnFirstItemTab('toDoIcon-0', 'toDo')"
                                >
                                    <img
                                        :src="tick1Icon"
                                        :class="(tab === 'toDo' ? 'iconToWhite ' : '') + 'icon mr-1'"
                                        alt=""
                                    >
                                    Unerledigt
                                </v-btn>
                            </v-badge>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span><span style="text-decoration: underline white">H</span>ausaufgaben (Alt + H)</span>
                            <div>
                                <img
                                    v-if="hoeren"
                                    :src="hausaufgabenMetacom"
                                    width="100"
                                    style="margin: auto"
                                    alt=""
                                    class="mr-1"
                                >
                                <img
                                    v-if="hoeren"
                                    :src="toDoMetacom"
                                    width="100"
                                    style="margin: auto"
                                    alt=""
                                >
                            </div>
                        </div>
                    </v-tooltip>
                </v-col>
                <v-col
                    class="px-1"
                    cols="4"
                >
                    <v-tooltip
                        :disabled="!showTooltips || !hoeren"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :id="'doneTab'"
                                :color="tab === 'done' ? '#F49F31' : 'white'"
                                :dark="tab === 'done'"
                                style="width: 100%; border: solid 1px #F49F31 !important; overflow: hidden; text-overflow: ellipsis"
                                elevation="0"
                                role="tab"
                                class="text-capitalize"
                                v-on="on"
                                @click="tab = 'done'; checkImagePreviews(); setFocusOn('doneTab')"
                                @keydown.tab="setFocusOnFirstItemTab('doneIcon-0', 'done')"
                            >
                                <img
                                    :src="tick2Icon"
                                    :class="(tab === 'done' ? 'iconToWhite ' : '') + 'icon mr-1'"
                                    alt=""
                                >
                                Erledigt
                            </v-btn>
                        </template>
                        <img
                            v-if="hoeren"
                            :src="hausaufgabenMetacom"
                            width="100"
                            style="margin: auto"
                            alt=""
                            class="mr-1"
                        >
                        <img
                            v-if="hoeren"
                            :src="doneMetacom"
                            width="100"
                            style="margin: auto"
                            alt=""
                        >
                    </v-tooltip>
                </v-col>
                <v-col
                    class="px-1"
                    cols="4"
                >
                    <v-tooltip
                        :disabled="!showTooltips || !hoeren"
                        bottom
                    >
                        <template v-slot:activator="{ on }">
                            <v-badge
                                color="#ff0000"
                                :content="correctedUnseenCount"
                                :value="correctedUnseenCount"
                                overlap
                                left
                                offset-x="10"
                                style="z-index: 8; width: 100%; height: 48px !important;"
                            >
                                <v-btn
                                    :id="'correctedTab'"
                                    :color="tab === 'corrected' ? 'var(--v-chatColor-base)' : 'white'"
                                    :dark="tab === 'corrected'"
                                    style="width: 100%; border: solid 1px #3ba934 !important; overflow: hidden; text-overflow: ellipsis"
                                    elevation="0"
                                    role="tab"
                                    class="text-capitalize"
                                    v-on="on"
                                    @click="tab = 'corrected'; checkImagePreviews(); setFocusOn('correctedTab')"
                                    @keydown.tab="setFocusOnFirstItemTab('correctedIcon-0', 'corrected')"
                                >
                                    <img
                                        :src="tick3Icon"
                                        :class="(tab === 'corrected' ? 'iconToWhite ' : '') + 'icon mr-1'"
                                        alt=""
                                    >
                                    Korrigiert
                                </v-btn>
                            </v-badge>
                        </template>
                        <img
                            v-if="hoeren"
                            :src="hausaufgabenMetacom"
                            width="100"
                            style="margin: auto"
                            alt=""
                            class="mr-1"
                        >
                        <img
                            v-if="hoeren"
                            :src="correctedMetacom"
                            width="100"
                            style="margin: auto"
                            alt=""
                        >
                    </v-tooltip>
                </v-col>
            </v-row>

            <!-- Hausiwidget ohne magnifier -->
            <div
                ref="recycleParent"
                @keydown.right="handleArrowKeyRight()"
                @keydown.left="handleArrowKeyLeft()"
            >
                <div v-if="tab ==='toDo' && filesVisibleToDo">
                    <v-row
                        v-show="false"
                        cols="12"
                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                    >
                        <v-col
                            style="display: flex; align-items: center"
                            class="sectionHeaderCol"
                        >
                            <img
                                :src="correctedIcon"
                                style="height: 30px"
                                class="sectionHeaderImg"
                                alt=""
                            >
                            <h2
                                class="pl-2 sectionHeaderText"
                                tabindex="0"
                            >
                                Unerledigt:
                            </h2>
                        </v-col>
                    </v-row>
                    <div v-if="learningPaths.length">
                        <div class="d-flex justify-center align-center mx-2 homeworkListHeader">
                            <div
                                style="height: 2px; background-color: var(--v-dunkelgrau-base);"
                            />
                            <div
                                class="d-flex align-center"
                                style="padding: 0.5em; justify-content: center;"
                            >
                                <p
                                    class="dateParagraph d-flex align-center"
                                    style="font-size: large; color: var(--v-dunkelgrau-base); text-align: center; justify-content: center; cursor: pointer"
                                >
                                    <img
                                        :src="fileIcon"
                                        class="icon20 mr-2"
                                    >
                                    {{ 'Lernpfade' }}
                                </p>
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            class="showSpeaker"
                                        >
                                            <v-btn
                                                v-if="accountRole === 'pupil'"
                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                small
                                                fab
                                                class="pa-0 ml-2 preventColorInvert"
                                                elevation="6"
                                                style="height: 30px; background-color:white; border-radius: 4px !important;"
                                                v-on="on"
                                                @click="readText('Lernpfad')"
                                            >
                                                <img
                                                    :src="lautsprecherIcon"
                                                    class="icon20"
                                                    alt="Vorlesen"
                                                >
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>Vorlesen</span>
                                </v-tooltip>
                            </div>
                            <div
                                style="height: 2px; background-color: var(--v-dunkelgrau-base);"
                            />
                        </div>
                        <RecycleScroller
                            v-slot="{ item }"
                            :items="learningPaths"
                            :item-size="recycleParent+heightOffset"
                            :item-secondary-size="recycleParent"
                            :grid-items="2"
                            key-field="_id"
                            class="recycleItem"
                        >
                            <div
                                class="learning-path-container"
                                @click.prevent="openLearningPath(item)"
                            >
                                <div class="learning-path-image-container">
                                    <img
                                        :src="lernpfadIcon"
                                        class="folder-display"
                                        alt="Lernpfad"
                                    >
                                </div>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="learning-path-button"
                                            color="#3ba934 "
                                            v-on="on"
                                            @click.stop="openLearningPath(item)"
                                        >
                                            <v-icon
                                                class="iconToWhite"
                                                size="x-large"
                                            >
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Lernpfad öffnen</span>
                                </v-tooltip>
                            </div>
                            <div class="learning-path-name ml-5">
                                {{ item.name }}
                            </div>
                        </RecycleScroller>
                    </div>
                    <div
                        v-if="showArchive && babyFilesToDoDisplay.length > 0"
                    >
                        <div
                            style="margin-top: 10px"
                        >
                            <v-btn
                                class="mr-2 text-capitalize"
                                style=" width: 80%;
                                        margin-left: 10%;
                                        margin-bottom: 5%;
                                        justify-content: space-between;
                                        "
                                elevation="0"
                                @click="toggleShowArchive"
                            >
                                <div
                                    style="display: flex; align-items: center"
                                >
                                    <v-icon style="margin-right: 5px">
                                        mdi-archive-outline
                                    </v-icon>
                                    <p
                                        style="font-weight:bold"
                                    >
                                        {{ !showArchive ? 'Archiv anzeigen' : 'Archiv ausblenden' }}
                                    </p>
                                </div>
                                <img
                                    :src="dropdownIcon"
                                    style="height: 20px; width:20px;"
                                    :style="showArchive ? 'transform: rotate(180deg)':''"
                                >
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-for="({ day, items }, index) in babyFilesToDoDisplay"
                        :key="`${index}${day}`"
                    >
                        <div class="d-flex justify-center align-center mx-2 homeworkListHeader">
                            <div
                                style="height: 2px; background-color: var(--v-dunkelgrau-base);"
                                :style="day ? 'width: 10%' : 'width: 8%'"
                            />
                            <div
                                class="d-flex align-center"
                                style="padding: 0.5em; justify-content: center;"
                                :style="day ? 'width: 80%' : 'width: 86%'"
                            >
                                <p
                                    :ref="`hausiItemstodo${day}`"
                                    class="dateParagraph d-flex align-center"
                                    style="font-size: large; color: var(--v-dunkelgrau-base); text-align: center; justify-content: center; cursor: pointer"
                                    @click="activateTranslationBtn(index, 'Todo')"
                                >
                                    <img
                                        :src="day ? stundenplanIcon : fileIcon"
                                        class="icon20 mr-2"
                                    >
                                    {{ day ? new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }) : 'Aufgaben aus Dateien' }}
                                </p>
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :ref="`speakerTodo${index}`"
                                            class="showSpeaker"
                                        >
                                            <v-btn
                                                v-if="accountRole === 'pupil'"
                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                small
                                                fab
                                                class="pa-0 ml-2 preventColorInvert"
                                                elevation="6"
                                                style="height: 30px; background-color:white; border-radius: 4px !important;"
                                                v-on="on"
                                                @click="readText(new Date(day).toLocaleDateString(targetLang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }), $event)"
                                            >
                                                <img
                                                    :src="lautsprecherIcon"
                                                    class="icon20"
                                                    alt="Vorlesen"
                                                >
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>Vorlesen</span>
                                </v-tooltip>
                            </div>
                            <div
                                style="height: 2px; background-color: var(--v-dunkelgrau-base);"
                                :style="day ? 'width: 10%' : 'width: 8%'"
                            />
                        </div>
                        <div
                            v-if="items && items.length && items.length !== 0"
                        >
                            <RecycleScroller
                                v-slot="{ item }"
                                :items="items"
                                :item-size="recycleParent+heightOffset"
                                :item-secondary-size="recycleParent"
                                :grid-items="2"
                                key-field="_id"
                                class="recycleItem"
                            >
                                <FileSystemTileElementV2
                                    v-if="item.title ? item.title.toLowerCase().includes(searchString.toLowerCase()) : item.uploadedName ? item.uploadedName.toLowerCase().includes(searchString.toLowerCase()) : false"
                                    :id="`homework${item._id}`"
                                    :ref="`homework${item._id}`"
                                    :type="'file'"
                                    :appointment="item.appointmentId"
                                    :item="item"
                                    :index="index"
                                    :category="'toDo'"
                                    :show-tooltips="showTooltips"
                                    :hoeren="hoeren"
                                    :sehen="sehen"
                                    :account="account"
                                    :show-tool-tips="true"
                                    :view-file-function="() => {}"
                                    :vfs="{ rulesetTask: false, appointment: item.appointmentId, isVfsTask: !item.appointmentId }"
                                    @openViewer="handleOpenAnsichtClick(item)"
                                    @uploadPupilAnswer="pupilUploadFile(item)"
                                    @downloadPupilUpload="clickDownloadPupilUpload(item)"
                                    @moveWithoutAnswer="() => { moveDialog = true; elementToMove = item }"
                                    @playVideo="play(item)"
                                    @playAudio="playAudio(item)"
                                    @getAllTeacherFiles="(fetch) => { getAllTeacherFiles(fetch) }"
                                    @loadFullscreenPreview="openVfsTask(item)"
                                    @download="downloadVfsTask(item)"
                                />
                            </RecycleScroller>
                        </div>
                        <div
                            v-else
                            class="emptyRow ma-2"
                            style="padding-top: 11vh"
                            align="center"
                            justify="center"
                        >
                            <img
                                :src="keineHausaufgabenIcon"
                                alt="Keine Aufgaben"
                                class="mt-2"
                                style="height: 100px"
                            >
                            <p
                                tabindex="0"
                                style="font-size: 20px"
                            >
                                Keine Aufgaben
                            </p>
                        </div>

                        <v-row
                            v-if="babyFilesToDoDisplay && babyFilesToDoDisplay.length === 0"
                            class="emptyRow ma-2"
                            align="center"
                            justify="center"
                        >
                            <v-col class="pa-0">
                                <p
                                    id="toDoEmpty"
                                    tabindex="0"
                                >
                                    Keine Aufgaben
                                </p>
                            </v-col>
                        </v-row>
                    </div>
                    <div
                        style="margin-top: 25px"
                    >
                        <v-btn
                            class="mr-2 text-capitalize"
                            style=" width: 80%;
                                    margin-left: 10%;
                                    margin-bottom: 5%;
                                    justify-content: space-between;
                                    "
                            elevation="0"
                            @click="toggleShowArchive"
                        >
                            <div
                                style="display: flex; align-items: center"
                            >
                                <v-icon style="margin-right: 5px">
                                    mdi-archive-outline
                                </v-icon>
                                <p
                                    style="font-weight:bold"
                                >
                                    {{ !showArchive ? 'Archiv anzeigen' : 'Archiv ausblenden' }}
                                </p>
                            </div>
                            <img
                                :src="dropdownIcon"
                                style="height: 20px; width:20px;"
                                :style="showArchive ? 'transform: rotate(180deg)':''"
                            >
                        </v-btn>
                    </div>
                </div>

                <div v-if="tab === 'done'">
                    <v-row
                        v-show="false"
                        cols="12"
                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                    >
                        <v-col
                            style="display: flex; align-items: center"
                            class="sectionHeaderCol"
                        >
                            <img
                                :src="correctedIcon"
                                style="height: 30px"
                                class="sectionHeaderImg"
                                alt=""
                            >
                            <h2
                                class="pl-2 sectionHeaderText"
                                tabindex="0"
                            >
                                Erledigt:
                            </h2>
                        </v-col>
                    </v-row>
                    <div
                        v-if="showArchive && babyFilesDoneDisplay.length > 0"
                    >
                        <div
                            style="margin-top: 10px"
                        >
                            <v-btn
                                class="mr-2 text-capitalize"
                                style=" width: 80%;
                                        margin-left: 10%;
                                        margin-bottom: 5%;
                                        justify-content: space-between;
                                        "
                                elevation="0"
                                @click="toggleShowArchive"
                            >
                                <div
                                    style="display: flex; align-items: center"
                                >
                                    <v-icon style="margin-right: 5px">
                                        mdi-archive-outline
                                    </v-icon>
                                    <p
                                        style="font-weight:bold"
                                    >
                                        {{ !showArchive ? 'Archiv anzeigen' : 'Archiv ausblenden' }}
                                    </p>
                                </div>
                                <img
                                    :src="dropdownIcon"
                                    style="height: 20px; width:20px;"
                                    :style="showArchive ? 'transform: rotate(180deg)':''"
                                >
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-for="({ day, items }, index) in babyFilesDoneDisplay"
                        :key="`${index}${day}`"
                    >
                        <div
                            v-if="items && items.length"
                            class="d-flex justify-center align-center mx-2 homeworkListHeader"
                        >
                            <div style="height: 2px; background-color: var(--v-dunkelgrau-base); width: 10%" />
                            <div
                                class="d-flex align-center"
                                style="width: 80%; padding: 0.5em; justify-content: center;"
                            >
                                <p
                                    :ref="`hausiItemsdone${day}`"
                                    class="dateParagraph d-flex align-center"
                                    style="font-size: large; color: var(--v-dunkelgrau-base); text-align: center; justify-content: center; cursor: pointer"
                                    @click="activateTranslationBtn(index, 'Done')"
                                >
                                    <img
                                        :src="day ? stundenplanIcon : fileIcon"
                                        class="icon20 mr-2"
                                    >
                                    {{ day ? new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }) : 'Aufgaben aus Dateien' }}
                                </p>
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :ref="`speakerDone${index}`"
                                            class="showSpeaker"
                                        >
                                            <v-btn
                                                v-if="accountRole === 'pupil'"
                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                small
                                                fab
                                                class="pa-0 ml-2 preventColorInvert"
                                                elevation="6"
                                                style="height: 30px; background-color:white; border-radius: 4px !important;"
                                                v-on="on"
                                                @click="readText(new Date(day).toLocaleDateString(targetLang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }), $event)"
                                            >
                                                <img
                                                    :src="lautsprecherIcon"
                                                    class="icon20"
                                                    alt="Vorlesen"
                                                >
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>Vorlesen</span>
                                </v-tooltip>
                            </div>
                            <div style="height: 2px; background-color: var(--v-dunkelgrau-base); width: 10%" />
                        </div>
                        <div
                            v-if="items && items.length"
                        >
                            <RecycleScroller
                                v-slot="{ item }"
                                :items="items"
                                :item-size="recycleParent+heightOffset"
                                :item-secondary-size="recycleParent"
                                :grid-items="2"
                                key-field="_id"
                                class="recycleItem"
                            >
                                <FileSystemTileElementV2
                                    v-if="item.title ? item.title.toLowerCase().includes(searchString.toLowerCase()) : item.uploadedName ? item.uploadedName.toLowerCase().includes(searchString.toLowerCase()) : false"
                                    :id="`homework${item._id}`"
                                    :ref="`homework${item._id}`"
                                    :type="'file'"
                                    :appointment="item.appointmentId"
                                    :item="item"
                                    :index="index"
                                    :category="'done'"
                                    :show-tooltips="showTooltips"
                                    :hoeren="hoeren"
                                    :sehen="sehen"
                                    :account="account"
                                    :show-tool-tips="true"
                                    :view-file-function="() => {}"
                                    :vfs="{ rulesetTask: false, appointment: item.appointmentId, isVfsTask: !item.appointmentId }"
                                    @openViewer="handleOpenAnsichtClick(item)"
                                    @uploadPupilAnswer="pupilUploadFile(item)"
                                    @downloadPupilUpload="clickDownloadPupilUpload(item)"
                                    @moveWithoutAnswer="() => { moveDialog = true; elementToMove = item }"
                                    @playVideo="play(item)"
                                    @playAudio="playAudio(item)"
                                    @getAllTeacherFiles="(fetch) => { getAllTeacherFiles(fetch) }"
                                    @loadFullscreenPreview="openVfsTask(item)"
                                    @download="downloadVfsTask(item)"
                                    @toggleArchiveElement="toggleArchiveElement(item, 'done')"
                                />
                            </RecycleScroller>
                        </div>
                    </div>
                    <div
                        v-if="babyFilesDoneDisplay && babyFilesDoneDisplay.length === 0"
                        class="d-flex emptyRow"
                        style="flex-direction: column; justify-content: center; padding-top: 13.5vh"
                    >
                        <img
                            :src="keineHausaufgabenIcon"
                            alt="Keine Abgaben"
                            style="height: 100px"
                        >
                        <p
                            id="doneEmpty"
                            class="mt-2"
                            style="font-size: 20px"
                            tabindex="0"
                        >
                            Aktuell gibt es nichts Erledigtes
                        </p>
                    </div>
                    <div
                        style="margin-top: 25px"
                    >
                        <v-btn
                            class="mr-2 text-capitalize"
                            style=" width: 80%;
                                    margin-left: 10%;
                                    margin-bottom: 5%;
                                    justify-content: space-between;
                                    "
                            elevation="0"
                            @click="toggleShowArchive"
                        >
                            <div
                                style="display: flex; align-items: center"
                            >
                                <v-icon style="margin-right: 5px">
                                    mdi-archive-outline
                                </v-icon>
                                <p
                                    style="font-weight:bold"
                                >
                                    {{ !showArchive ? 'Archiv anzeigen' : 'Archiv ausblenden' }}
                                </p>
                            </div>
                            <img
                                :src="dropdownIcon"
                                style="height: 20px; width:20px;"
                                :style="showArchive ? 'transform: rotate(180deg)':''"
                            >
                        </v-btn>
                    </div>
                </div>

                <div v-if="tab === 'corrected'">
                    <v-row
                        v-show="false"
                        cols="12"
                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                    >
                        <v-col
                            style="display: flex; align-items: center"
                            class="sectionHeaderCol"
                        >
                            <img
                                :src="correctedIcon"
                                style="height: 30px"
                                class="sectionHeaderImg"
                                alt=""
                            >
                            <h2
                                class="pl-2 sectionHeaderText"
                                tabindex="0"
                            >
                                Korrigiert:
                            </h2>
                        </v-col>
                    </v-row>
                    <div
                        v-if="showArchive && babyFilesCorrectedDisplay.length > 0"
                    >
                        <div
                            style="margin-top: 10px"
                        >
                            <v-btn
                                class="mr-2 text-capitalize"
                                style=" width: 80%;
                                        margin-left: 10%;
                                        margin-bottom: 5%;
                                        justify-content: space-between;
                                        "
                                elevation="0"
                                @click="toggleShowArchive"
                            >
                                <div
                                    style="display: flex; align-items: center"
                                >
                                    <v-icon style="margin-right: 5px">
                                        mdi-archive-outline
                                    </v-icon>
                                    <p
                                        style="font-weight:bold"
                                    >
                                        {{ !showArchive ? 'Archiv anzeigen' : 'Archiv ausblenden' }}
                                    </p>
                                </div>
                                <img
                                    :src="dropdownIcon"
                                    style="height: 20px; width:20px;"
                                    :style="showArchive ? 'transform: rotate(180deg)':''"
                                >
                            </v-btn>
                        </div>
                    </div>
                    <div
                        v-for="({ day, items }, index) in babyFilesCorrectedDisplay"
                        :key="`${index}${day}`"
                    >
                        <div
                            v-if="items && items.length"
                            class="d-flex justify-center align-center mx-2 homeworkListHeader"
                        >
                            <div style="height: 2px; background-color: var(--v-dunkelgrau-base); width: 10%" />
                            <div
                                class="d-flex align-center"
                                style="width: 80%; padding: 0.5em; justify-content: center;"
                            >
                                <p
                                    :ref="`hausiItemscorrected${day}`"
                                    class="dateParagraph d-flex align-center"
                                    style="font-size: large; color: var(--v-dunkelgrau-base); text-align: center; justify-content: center; cursor: pointer"
                                    @click="activateTranslationBtn(index, 'Corrected')"
                                >
                                    <img
                                        :src="day ? stundenplanIcon : fileIcon"
                                        class="icon20 mr-2"
                                    >
                                    {{ day ? new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }) : 'Aufgaben aus Dateien' }}
                                </p>
                                <v-tooltip
                                    :disabled="!showTooltips"
                                    bottom
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                            :ref="`speakerCorrected${index}`"
                                            class="showSpeaker"
                                        >
                                            <v-btn
                                                v-if="accountRole === 'pupil'"
                                                :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                small
                                                fab
                                                class="pa-0 ml-2 preventColorInvert"
                                                elevation="6"
                                                style="height: 30px; background-color:white; border-radius: 4px !important;"
                                                v-on="on"
                                                @click="readText(new Date(day).toLocaleDateString(targetLang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }), $event)"
                                            >
                                                <img
                                                    :src="lautsprecherIcon"
                                                    class="icon20"
                                                    alt="Vorlesen"
                                                >
                                            </v-btn>
                                        </div>
                                    </template>
                                    <span>Vorlesen</span>
                                </v-tooltip>
                            </div>
                            <div style="height: 2px; background-color: var(--v-dunkelgrau-base); width: 10%" />
                        </div>
                        <div
                            v-if="items && items.length"
                        >
                            <RecycleScroller
                                v-slot="{ item }"
                                :items="items"
                                :item-size="recycleParent+heightOffset"
                                :item-secondary-size="recycleParent"
                                :grid-items="2"
                                key-field="_id"
                                class="recycleItem"
                            >
                                <FileSystemTileElementV2
                                    v-if="item.title ? item.title.toLowerCase().includes(searchString.toLowerCase()) : item.uploadedName ? item.uploadedName.toLowerCase().includes(searchString.toLowerCase()) : false"
                                    :id="`homework${item._id}`"
                                    :ref="`homework${item._id}`"
                                    :type="'file'"
                                    :appointment="item.appointmentId"
                                    :item="item"
                                    :index="index"
                                    :category="'corrected'"
                                    :show-tooltips="showTooltips"
                                    :hoeren="hoeren"
                                    :sehen="sehen"
                                    :account="account"
                                    :show-tool-tips="true"
                                    :view-file-function="() => {}"
                                    :vfs="{ rulesetTask: false, appointment: item.appointmentId, isVfsTask: !item.appointmentId }"
                                    @openViewer="handleOpenAnsichtClick(item)"
                                    @uploadPupilAnswer="pupilUploadFile(item)"
                                    @downloadPupilUpload="clickDownloadPupilUpload(item)"
                                    @moveWithoutAnswer="() => { moveDialog = true; elementToMove = item }"
                                    @playVideo="play(item)"
                                    @playAudio="playAudio(item)"
                                    @getAllTeacherFiles="(fetch) => { getAllTeacherFiles(fetch) }"
                                    @loadFullscreenPreview="openVfsTask(item)"
                                    @download="downloadVfsTask(item)"
                                    @toggleArchiveElement="toggleArchiveElement(item, 'corrected')"
                                />
                            </RecycleScroller>
                        </div>
                    </div>
                    <div
                        v-if="babyFilesCorrectedDisplay && babyFilesCorrectedDisplay.length === 0"
                        class="d-flex emptyRow"
                        style="flex-direction: column; justify-content: center; padding-top: 13.5vh"
                    >
                        <img
                            :src="keineHausaufgabenIcon"
                            alt="Keine Aufgaben"
                            style="height: 100px"
                        >
                        <p
                            id="correctedEmpty"
                            class="mt-2"
                            style="font-size: 20px"
                            tabindex="0"
                        >
                            Aktuell gibt es nichts Korrigiertes
                        </p>
                    </div>
                    <div
                        style="margin-top: 25px"
                    >
                        <v-btn
                            class="mr-2 text-capitalize"
                            style=" width: 80%;
                                    margin-left: 10%;
                                    margin-bottom: 5%;
                                    justify-content: space-between;
                                    "
                            elevation="0"
                            @click="toggleShowArchive"
                        >
                            <div
                                style="display: flex; align-items: center"
                            >
                                <v-icon style="margin-right: 5px">
                                    mdi-archive-outline
                                </v-icon>
                                <p
                                    style="font-weight:bold"
                                >
                                    {{ !showArchive ? 'Archiv anzeigen' : 'Archiv ausblenden' }}
                                </p>
                            </div>
                            <img
                                :src="dropdownIcon"
                                style="height: 20px; width:20px;"
                                :style="showArchive ? 'transform: rotate(180deg)':''"
                            >
                        </v-btn>
                    </div>
                </div>

                <div v-if="tab === 'all'">
                    <v-row
                        v-show="false"
                        cols="12"
                        style="width: 99%; margin: 0.5em auto 0.5em auto;"
                    >
                        <v-col
                            style="display: flex; align-items: center"
                            class="sectionHeaderCol"
                        >
                            <img
                                :src="correctedIcon"
                                style="height: 30px"
                                class="sectionHeaderImg"
                                alt=""
                            >
                            <h2
                                class="pl-2 sectionHeaderText"
                                tabindex="0"
                            >
                                Erledigt:
                            </h2>
                        </v-col>
                    </v-row>

                    <div
                        v-for="({ day, items }, index) in allBabyFilesDisplay"
                        :key="`${index}${day}`"
                    >
                        <div class="d-flex justify-center align-center mx-2 homeworkListHeader">
                            <div style="height: 2px; background-color: var(--v-dunkelgrau-base); width: 25%" />
                            <p
                                :ref="`hausiItemsAll${day}`"
                                class="dateParagraph"
                                style="font-size: large; width: 50%; padding: 0.5em; color: var(--v-dunkelgrau-base); text-align: center"
                            >
                                {{ new Date(day).toLocaleDateString(targetLang, { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }) }}
                            </p>
                            <div style="height: 2px; background-color: var(--v-dunkelgrau-base); width: 25%" />
                        </div>
                        <div
                            v-if="items && items.length"
                        >
                            <div
                                v-for="(item, index) in items"
                                :key="item.file._id"
                                class="homeworkListItem"
                            >
                                <div v-if="item.title.toLowerCase().includes(searchString.toLowerCase())">
                                    <FileSystemTileElementV2
                                        :type="'file'"
                                        :appointment="true"
                                        :item="item"
                                        :index="index"
                                        :category="'all'"
                                        :show-tooltips="showTooltips"
                                        :hoeren="hoeren"
                                        :sehen="sehen"
                                        :account="account"
                                        :show-tool-tips="true"
                                        :view-file-function="() => {}"
                                        :vfs="{ rulesetTask: false, appointment: true, isVfsTask: !item.isAssignment }"
                                        @openViewer="handleOpenAnsichtClick(item)"
                                        @uploadPupilAnswer="pupilUploadFile(item)"
                                        @downloadPupilUpload="clickDownloadPupilUpload(item)"
                                        @moveWithoutAnswer="() => { moveDialog = true; elementToMove = item }"
                                        @playVideo="play(item)"
                                        @playAudio="playAudio(item)"
                                        @getAllTeacherFiles="(fetch) => { getAllTeacherFiles(fetch) }"
                                        @loadFullscreenPreview="openVfsTask(item)"
                                        @download="downloadVfsTask(item)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-row
                        v-if="allBabyFiles && allBabyFiles.length === 0"
                        class="emptyRow ma-2"
                        align="center"
                        justify="center"
                    >
                        <v-col class="pa-0">
                            <p
                                id="doneEmpty"
                                tabindex="0"
                            >
                                Aktuell gibt es noch keine Aufgaben.
                            </p>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>

        <!-- popups, overlays and snackbars -->

        <!-- Normal Video Popup -->
        <v-dialog
            v-if="videoUrl"
            :value="videoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            v-if="primaryVideoUrl && secondaryVideoUrl"
            :value="primaryVideoUrl && secondaryVideoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="videoInVideo"
                        size-controls
                        :primary-video-src="primaryVideoUrl"
                        :secondary-video-src="secondaryVideoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <v-dialog
            v-if="audioUrl"
            overlay-opacity=".9"
            :value="audioUrl"
            z-index="95"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="audioUrl = null;"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="audioUrl = null;"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="end">
                <v-col cols="auto">
                    <audio
                        id="audioPlayer"
                        :src="audioUrl"
                        controls
                        :width="windowWidth > 900 ? '79%' : '39%'"
                    >
                        Your browser does not support the video tag.
                    </audio>
                </v-col>
            </v-row>
        <!--            <v-icon alt="Audio schließen" tabindex="2" large style="position: fixed; top: 50px; right: 50px; z-index: 99" @click="audioUrl = false" dark>-->
        <!--                fas fa-times-->
        <!--            </v-icon>-->
        <!--            <audio id="audioPlayer" alt="" tabindex="1" :src="audioUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
        <!--                   :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
        <!--                Your browser does not support the video tag.-->
        <!--            </audio>-->
        </v-dialog>


        <v-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            max-width="350px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title class="d-flex align-center justify-space-between">
                    <p>Löschen bestätigen</p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>unwiderruflich</strong> löschen?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="clickDeleteUpload"
                    >
                        <img
                            :src="papierkorbIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Löschen
                    </v-btn>

                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="moveDialog"
            v-model="moveDialog"
            max-width="350px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: var(--v-fileArchiveOrange-base);"
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Abgeben bestätigen
                    </p>
                    <v-btn
                        x-small
                        text
                        elevation="0"
                        class="pa-0"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    Willst du die Datei wirklich <strong>ohne</strong> eine Lösung/Abgabe abgeben?
                </v-card-text>

                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="rot"
                        dark
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        class="mr-2 text-capitalize"
                        color="gruen"
                        dark
                        @click="moveUploadToDone(elementToMove)"
                    >
                        <img
                            :src="fertigIcon"
                            class="iconToWhite mr-2"
                            alt=""
                        >
                        Abgeben
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { allowedMimeTypesForLibre } from "@/constants/allowedMimeTypes";
import lautsprecherWeissAusIcon from '@/assets/Icons/lautsprecher-weiss-aus-89.svg'
import doneIcon from '@/assets/Icons/erledigt-orange-90.svg'
import correctedIcon from '@/assets/Icons/korrigiert-orange-91.svg'
import papierkorbIcon from "@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg";
import schliesenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import fertigIcon from '@/assets/Icons/fertig-74.svg'
import tick1Icon from '@/assets/Icons/tick-1.svg'
import tick2Icon from '@/assets/Icons/tick-2.svg'
import tick3Icon from '@/assets/Icons/tick-3.svg'
import keineHausaufgabenIcon from '@/assets/Icons/FaceliftIcons/keine_hausaufgaben_dateien.svg';
import fileIcon from '@/assets/Icons/file.svg';
import stundenplanIcon from '@/assets/Icons/timetable.svg';
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import dropdownIcon from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import lernpfadIcon from '@/assets/Icons/FaceliftIcons/Lernpfad.svg';
import hausaufgabenMetacom from '@/assets/METACOM/Hausis/hausaufgaben.png'
import toDoMetacom from '@/assets/METACOM/Hausis/machen.png'
import doneMetacom from '@/assets/METACOM/Hausis/beenden.png'
import correctedMetacom from '@/assets/METACOM/Hausis/Korrektur.png'

import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import * as backend from "../../../api/backend";
import ProgressBar from "../../ProgressBar";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import sanitizeHtml from '@/util/sanitizeHtml';
import { textToSpeech } from "@/util/textToSpeech";
import FileSystemTileElementV2 from "../../FileArchive/FileSystemTileElementV2";
import EventBus from '../../../util/eventBus';
import { RecycleScroller } from 'vue-virtual-scroller';
import urlify from '@/util/urlify';

export default {
    name: "HomeworkCategoryContainer",
    components: {
        VideoInVideo,
        // AntiScroll,
        ProgressBar,
        FileSystemTileElementV2,
    },
    props: {
        small: { required: false, default: false },
        activeFilters: { required: false, type: Array },
        searchString: { required: false, type: String, default: '' },
    },
    data: () => ({
        subtitleURL: null,
        noSubtitle: false,
        tab: 'all',
        showPupilUploadProgress: false,
        pupilUploadProgress: 0.0,
        pupilXmlHttpRequest: null,
        appointments: [],

        allBabyFiles: [],
        babyFilesToDo: [],
        babyFilesCorrected: [],
        babyFilesDone: [],

        /*to toggle btwn showing 3 items and whole list*/
        allBabyFilesDisplay: [],
        babyFilesToDoDisplay: [],
        babyFilesCorrectedDisplay: [],
        babyFilesDoneDisplay: [],
        showLoading: false,

        /* archive section */
        showArchive: false,
        showArchiveRequested: false,
        archive: null,

        vfs: null,
        vfsTasks: [],
        learningPaths: [],
        recycleParent: 100,
        heightOffset: 0,

        person: {
            accessibility: {
                screenreader: false,
            }
        },
        account: null,
        videoUrl: null,
        audioUrl: null,
        videoLoading: false,
        primaryVideoUrl: null,
        secondaryVideoUrl: null,

        sehen: false,
        hoeren: false,
        babyView: false,

        deleteDialog: false,
        elementToDelete: null,
        moveDialog: false,
        elementToMove: null,

        //#region unseen badges stuff
        //#endregion

        doneIcon,
        correctedIcon,
        papierkorbIcon,
        schliesenIcon,
        fertigIcon,
        tick1Icon,
        tick2Icon,
        tick3Icon,
        keineHausaufgabenIcon,
        fileIcon,
        stundenplanIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,
        dropdownIcon,

        hausaufgabenMetacom,
        toDoMetacom,
        doneMetacom,
        correctedMetacom,
        lernpfadIcon,

        showTextTimeout: null,
        sanitizeHtml,
        urlify,
        me: {
            accessibility: {
                screenreader: false,
            }
        },

        compareFunction : (a, b) => {
            let result = 0;
            if (a.day > b.day) {
                result = -1;
            } else if (a.day < b.day) {
                result = 1;
            }
            return result;
        },
    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('translation', ['targetLang']),
        ...mapGetters('auth', [ 'accountRole', 'accountId' ]),
        ...mapGetters('pupils', ['getTutorialProgress']),
        ...mapGetters('util', ['currentlyOpenTeacherUploadId', 'windowWidth']),
        ...mapState('magnifier', ["magnifier"]),
        ...mapState('util', ['keyboard', 'windowWidth']),
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters("gamepad", {gamepadBButtonPressed: "bButtonPressed"}),
        ...mapGetters("auth", ["accountRole"]),

        filesVisibleToDo(){
            return this.babyFilesToDoDisplay.length || this.learningPaths.length;
        },
        todoUnseenCount() {
            let result = -1;
            result = this.babyFilesToDo.filter(file => !file.seenByMe).length;
            this.setBabyFilesToDoBadge(result);
            return result;
        },
        correctedUnseenCount() {
            let result = -1;
            result = this.babyFilesCorrected.filter(file => !file.seenByMe).length;
            this.setBabyFilesCorrectedBadge(result);
            return result;
        },
        homeworkBadgeEvents() {
            const eventList = [];
            if (this.babyFilesToDoDisplay.length) {
                this.babyFilesToDoDisplay.forEach((entry) => {
                    const day = entry.day;
                    entry.items.forEach((upload) => {
                        if (!upload.seenByMe && (upload.seenBy ? !upload.seenBy.includes(this.accountId) : true)) {
                            eventList.push({
                                type: 'homework',
                                category: 'toDo',
                                item: upload._id,
                                file: upload.file ? upload.file._id || upload.file : upload._id,
                                day: day,
                            });
                        }
                    })
                });
            }
            // currently hiding done badges due to badge overload
            // if (this.babyFilesDoneDisplay.length) {
            //     this.babyFilesDoneDisplay.forEach((entry) => {
            //         const day = entry.day;
            //         entry.items.forEach((upload) => {
            //             if (!upload.seenByMe) {
            //                 eventList.push({
            //                     type: 'homework',
            //                     category: 'done',
            //                     item: upload._id,
            //                     file: upload.file ? upload.file._id || upload.file : upload._id,
            //                     day: day,
            //                 });
            //             }
            //         })
            //     });
            // }
            if (this.babyFilesCorrectedDisplay.length) {
                this.babyFilesCorrectedDisplay.forEach((entry) => {
                    const day = entry.day;
                    entry.items.forEach((upload) => {
                        if (!upload.seenByMe && (upload.seenBy ? !upload.seenBy.includes(this.accountId) : true)) {
                            eventList.push({
                                type: 'homework',
                                category: 'corrected',
                                item: upload._id,
                                file: upload.file ? upload.file._id || upload.file : upload._id,
                                day: day,
                            });
                        }
                    })
                });
            }
            const compareFunction = (a, b) => {
                let result = 0;
                if (a.day > b.day) {
                    result = -1;
                } else if (a.day < b.day) {
                    result = 1;
                }
                return result;
            }
            eventList.sort(compareFunction);
            return eventList;
        },
    },
    watch: {
        word(newVal) {
            if (newVal === 'aufgaben') {
                this.focusOnHausaufgaben();
            }
        },
        async currentlyOpenTeacherUploadId(newVal, oldVal) {
            if (oldVal && !newVal) {
                await this.getAllTeacherFiles();
                await this.sliceLists();
            }
        },
        gamepadBButtonPressed(newVal, oldVal) {
            // on keyup of gamepadYButton
            if (oldVal === true && newVal === false) {
                this.focusOnHausaufgaben();
            }
        },
        tab(newVal) {
            setTimeout(() => {
                let today = new Date();
                today.setHours(0,0,0,0);
                // if (this.$refs[`hausiItems${newVal}${today.toISOString()}`] && this.windowWidth > 900) {
                //      this.$refs[`hausiItems${newVal}${today.toISOString()}`][0]
                //          .scrollIntoView(true, { behavior: 'smooth', block: 'center', inline: 'center' });
                // }
            }, 1000);
        },
        activeFilters: {
            immediate: true,
            handler() {
                if (this.activeFilters.length) {
                    for (let i = 0; i < this.activeFilters.length; i++) {
                        switch(this.activeFilters[i].id) {
                            case 'todo':
                                this.tab = 'toDo';
                                break;
                            case 'done':
                                this.tab = 'done';
                                break;
                            case 'corrected':
                                this.tab = 'corrected';
                                break;
                            default:
                                this.tab = 'all';
                                break;
                        }
                    }
                } else {
                    this.tab = 'all';
                }
            },
        },
        '$route.query': {
            async handler(query) {
                if (query.aId && this.babyFilesToDoDisplay.length) {
                    const aId = query.aId;
                    const filesToHighlight = [];
                    this.babyFilesToDoDisplay.forEach((displayDate) => {
                        displayDate.items.forEach((file) => {
                            if(file.appointmentId === aId) {
                                filesToHighlight.push(file);
                            }
                        })
                    })

                    await this.$nextTick(() => {
                        const element = this.$refs[`homework${filesToHighlight[0]._id}`][0];
                        element.$el.scrollIntoView({
                            behaviour: 'smooth',
                            block: 'end',
                        });
                        this.highlightHomework(element.$el);
                    });

                    for(let i = 1; i < filesToHighlight.length; i++) {
                        const element = this.$refs[`homework${filesToHighlight[i]._id}`][0];
                        this.highlightHomework(element.$el);
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    async created() {
        this.account = await this.getCurrentAccount();
        let userInfo;
        if (this.account.role === 'pupil') {
            userInfo = await this.getMePupil();
        } else if (this.account.role === 'teacher') {
            userInfo = await this.getMeTeacher();
        }
        // start: add tasks from vfs to homework list
        // todo: sharing_Files in root, learningPath
        this.vfs = await this.getVFSFolderUploadsV2({
            mounting: true
        });
        await this.probeTasks(this.vfs);
        // end: add tasks from vfs to homework list
        this.person = userInfo;
        this.account = userInfo.account;
        this.babyView = userInfo.babyView;
        if (userInfo.accessibility) {
            this.sehen = userInfo.accessibility.sehen;
            this.hoeren = userInfo.accessibility.hoeren;
        } else {
            this.sehen = false;
            this.hoeren = false;
        }
        //to check if they are babys
        if (this.babyView) {
            this.babyViewTut = true;
        }
        await this.getAllTeacherFiles(true);
        await this.sliceLists();
    },
    async mounted() {
        if (this.accountRole === 'pupil') {
            this.me = await this.getMePupil();
            this.archive = await backend.getMyArchive();
            this.archive = await this.archive.json();
        }
        window.addEventListener("keydown", this.handleKeyUp);
        await this.requestAppointments();
        this.$emit('setTotalBadges', this.homeworkBadgeEvents);
        // receive websocket events on modified content
        EventBus.$on('vfs-submit-task', async (body) => {
            // console.log('vfs-submit-task', body);
        });
        EventBus.$on('vfs-new-task', async (body) => {
            // console.log('vfs-new-task', body);
            await this.refreshContent();
        });
        EventBus.$on('vfs-delete-task', async (body) => {
            // console.log('vfs-delete-task', body);
            await this.refreshContent();
        });
        EventBus.$off('appointment-new-hw');
        EventBus.$on('appointment-new-hw', async (createdAppointment) => {
            this.appointments.push(createdAppointment);
            await this.refreshContent();
        });
        EventBus.$off('teacherupload-new-hw');
        EventBus.$on('teacherupload-new-hw', async (body) => {
            const { createdUpload, appointmentId, fileAccessToken } = body;
            if (!createdUpload || !appointmentId || !fileAccessToken) {
                return;
            }
            const foundAppointment = this.appointments.find((appointment) => appointment._id === appointmentId);
            if (!foundAppointment) {
                return;
            }
            createdUpload.fileAccessToken = fileAccessToken;
            foundAppointment.teacherUploads.push(createdUpload);
            await this.refreshContent();
        });
        EventBus.$off('teacherupload-delete-hw');
        EventBus.$on('teacherupload-delete-hw', async (body) => {
            const { appointmentId, uploadId } = body;
            if (!appointmentId || !uploadId) {
                return;
            }
            const foundAppointment = this.appointments.find((appointment) => appointment._id === appointmentId);
            if (!foundAppointment) {
                return;
            }
            foundAppointment.teacherUploads.filter((teacherUpload) => teacherUpload._id !== uploadId);
            await this.refreshContent();
        });
        EventBus.$off('pupilupload-edit-hw');
        EventBus.$on('pupilupload-edit-hw', async (body) => {
            const { createdUpload, appointmentId } = body;
            if (!createdUpload || !appointmentId) {
                return;
            }
            const foundAppointment = this.appointments.find((appointment) => appointment._id === appointmentId);
            if (!foundAppointment) {
                return;
            }
            foundAppointment.teacherUploads.push(createdUpload);
            const foundOriginalPupilUpload = foundAppointment.pupilUpload.find((pupilUpload) => pupilUpload._id === createdUpload.editParent);
            if (!foundOriginalPupilUpload) {
                return;
            }
            foundOriginalPupilUpload.editLeafs.push(createdUpload._id);
            await this.refreshContent();
        });
        EventBus.$off('pupilupload-delete-hw');
        EventBus.$on('pupilupload-delete-hw', async (body) => {
            const {appointmentId, uploadId} = body;
            if (!appointmentId || !uploadId) {
                return;
            }
            const foundAppointment = this.appointments.find((appointment) => appointment._id === appointmentId);
            if (!foundAppointment) {
                return;
            }
            foundAppointment.pupilUpload = foundAppointment.pupilUpload.filter((upload) => upload._id !== uploadId);
            await this.refreshContent();
        });
        this.recycleParent = this.$refs.recycleParent.clientWidth/2;
        // since recyclescroller cannot run fully responsive, approximate a solid height offset for each display type
        if (this.windowWidth <= 400) {
            // starting with single view
            this.heightOffset = 30;
        } else if (this.windowWidth > 400 && this.windowWidth <= 600) {
            this.heightOffset = 20;
        } else if (this.windowWidth > 600 && this.windowWidth <= 750) {
            this.heightOffset = -40;
        } else if (this.windowWidth > 750 && this.windowWidth < 900) {
            this.heightOffset = -100;
        }  else if (this.windowWidth >= 900 && this.windowWidth <= 1000) {
            // we are back to large view from this point
            this.heightOffset = 50;
        } else if (this.windowWidth > 1000 && this.windowWidth <= 1200) {
            this.heightOffset = 40;
        } else if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
            this.heightOffset = -20;
        } else if (this.windowWidth > 1450) {
            this.heightOffset = -30;
        }
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyUp);
    },
    methods: {
        ...mapActions('appointments', ['getAppointments']),
        ...mapActions('pupils', ['setTutorialProgress', 'createPupil', 'getMePupil',]),
        ...mapActions('teachers', ['getMeTeacher']),
        ...mapActions("auth", ["getCurrentAccount"]),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('util', ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleOpenTeacherUploadId', 'insertLastOpenedInEditor']),
        ...mapActions('tooltips', ['triggerShortcutTooltips']),
        ...mapActions("virtualFileSystem", ["getVFSFolderUploadV2", "getVFSFolderUploadsV2"]),
        ...mapActions('mimeTypes', ['getFileExtension']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations('badges', ['setBabyFilesToDoBadge', 'setBabyFilesCorrectedBadge']),
        ...mapMutations('vfs', ['setGroupFolderSelection', 'setVfs', 'setOriginalFileIdForVFSDraft', 'setLibreIsTask', 'setLibreFileId']),

        highlightHomework(element) {
            element.classList.remove('badgeEventVisualize');
            element.classList.add('badgeEventVisualize');

            setTimeout(() => {
                element.classList.remove('badgeEventVisualize');
            }, 5050);
        },


        handleKeyUp(event) {
            if (event.altKey && event.key === 'h') {
                this.focusOnHausaufgaben();
                this.triggerShortcutTooltips();
            }
        },
        focusOnHausaufgaben() {
            this.tab = 'toDo';
            if (!this.magnifier) {
                if (this.babyFilesToDoDisplay.length === 0) {
                    document.getElementById("toDoTab").focus();
                } else {
                    document.getElementById("toDoItem-0").focus();
                }
            } else {
                if (this.babyFilesToDoDisplay.length === 0) {
                    document.getElementById("toDoTabMagnifier").focus();
                } else {
                    document.getElementById("toDoItemMagnifier-0").focus();
                }
            }
        },
        async refreshContent() {
            this.vfsTasks = [];
            this.vfs = await this.getVFSFolderUploadsV2();
            await this.probeTasks(this.vfs);
            await this.getAllTeacherFiles(true);
        },

        async probeTasks(folder) {
                if(folder.learningPath){
                    this.learningPaths.push(folder);
                }
                if (folder.sharing_Tasks) {
                    await Promise.all(folder.sharing_Tasks.map((task) => {
                        if (!task.activeTask) {
                            return;
                        }
                        const foundSubmission = task.submissions.find((submission) => {
                            return (submission ? submission.account : false) == this.accountId;
                        });
                        if (foundSubmission) {
                            if (foundSubmission.file) {
                                // push the submitted or corrected file
                                if (foundSubmission.seenByTeacher) {
                                    foundSubmission.file.corrected = true;
                                } else {
                                    foundSubmission.file.submitted = true;
                                }
                                // foundSubmission.task
                                this.vfsTasks.push(foundSubmission.file);
                            }
                        } else {
                            // push the original file -> task not completed yet
                           if(folder.learningPath){
                            return
                           }
                           else{

                            const file = folder.files.find((file) => {
                                return file._id == (task.file._id ? task.file._id : task.file);
                            });
                            if (file) {
                                file.todo = true;
                                this.vfsTasks.push(file);
                            }
                           }
                        }
                    }));
                }
                if (folder.folders && !folder.learningPath) {
                await Promise.all(folder.folders.map(async (subFolder) => {
                    await this.probeTasks(subFolder);
                }));
            }
        },

        async clickDeleteUpload() {
            let file = this.elementToDelete;

            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === this.elementToDelete.appointmentId)[0];
            const fileToDelete = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.deleteUpload((fileToDelete._id).toString());

            if (res.status === 204) {
                this.showSnackbar({message: `Datei ${file.title} erfolgreich gelöscht`});
            } else {
                this.showSnackbar({message: 'Beim Löschen ist ein Fehler aufgetreten', color: 'error'});
            }

            this.deleteDialog = false;

            await this.getAllTeacherFiles(); //reload files and sort in tabs/arrays
            this.elementToDelete = null; //clear temp reference to file
        },

        async requestAppointments() {
            this.appointments = await this.getAppointments();
        },

        async downloadVfsTask(item) {
            const res = await this.getVFSFolderUploadV2(
                item._id
            );
            fetch(res.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
            })
                .then((response) => response.blob())
                .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = item.uploadedName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },

        async getAllTeacherFiles(fetch) {
            this.showLoading = true;

            this.babyFilesToDo = [];
            this.babyFilesCorrected = [];
            this.babyFilesDone = [];
            this.allBabyFiles = [];
            // todo clean up: add babyFilesTodo from vfsTasks into hausiwidget
            this.vfsTasks.forEach((el) => {
                if (el.todo) {
                    this.babyFilesToDo.push(el);
                } else if (el.submitted) {
                    this.babyFilesDone.push(el);
                } else if (el.corrected) {
                    this.babyFilesCorrected.push(el);
                } else {
                    console.log('missing vfsTask category information (todo,subm,corr)');
                }
            });
            await this.getAppointments(fetch).then(async (appointments) => {
                for (let i = 0; i < appointments.length; i++) {
                    const appointment = appointments[i];
                    if (appointment.teacherUploads.length) {
                        for (let j = 0; j < appointment.teacherUploads.length; j++) {
                            // Check if this is a picture in picture record -> don't show this
                            const element = { ...appointment.teacherUploads[j] };
                            // Add upload if there is no canPip-Property or if its true
                            element.fachIcon = appointment.schoolSubject.icon;
                            element.fachColor = appointment.schoolSubject.color;
                            element.groupName = appointment.schoolSubject.name;
                            element.appointmentId = appointment._id;
                            element.isDone = appointment.teacherUploads[j].editLeafs.length > 0;
                            element.previewData = null;
                            element.day = appointment.day;
                            // The default preview data (only added if some files are really there)
                            let previewData = {
                                fileId: element.file,
                                loading: false,
                                fetched: false,
                                preview: null
                            }
                            if (element.isMoved && element.isMoved.length > 0) {
                                if (element.isMoved.find((acc) => acc == this.accountId)) {
                                    element.previewData = previewData;
                                    this.babyFilesDone.push(element);
                                    this.allBabyFiles.push(element);
                                }
                            } else if (this.isOpen(appointment, element)) {
                                element.previewData = previewData;
                                this.babyFilesToDo.push(element);
                                this.allBabyFiles.push(element);
                            } else if (element.editParent) {
                                let correctedFile = appointment.pupilUpload.filter(
                                    upload => upload.editLeafs && upload.editLeafs.length > 0 && upload.editParent === element._id);
                                if (correctedFile && correctedFile.length > 0 && correctedFile.editLeafs && correctedFile.editLeafs.leading > 0) {
                                    // Only set the preview data if there is a corrected file
                                    // Set the correct id of the file
                                    previewData.fileId = correctedFile[0].editLeafs[correctedFile[0].editLeafs.length - 1];
                                    element.previewData = previewData;
                                }
                                this.babyFilesCorrected.push(element);
                                this.allBabyFiles.push(element);
                            } else {
                                let pupilFile = appointment.pupilUpload.filter(upload => upload.uploader === this.account && upload.editParent === element._id);
                                if (pupilFile && pupilFile.length > 0) {
                                    previewData.fileId = pupilFile[0].file;
                                    element.previewData = previewData;
                                } else {
                                    previewData = null;
                                }
                            }
                        }
                    }
                    if (appointment.pupilUpload.length) {
                        for (let j = 0; j < appointment.pupilUpload.length; j++) {
                            // Check if this is a picture in picture record -> don't show this
                            const element = { ...appointment.pupilUpload[j] };
                            // Add upload if there is no canPip-Property or if its true
                            element.fachIcon = appointment.schoolSubject.icon;
                            element.fachColor = appointment.schoolSubject.color;
                            element.groupName = appointment.schoolSubject.name;
                            element.appointmentId = appointment._id;
                            element.isDone = appointment.pupilUpload[j].editLeafs.length > 0;
                            element.previewData = null;
                            element.day = appointment.day;
                            // The default preview data (only added if some files are really there)
                            let previewData = {
                                fileId: element.file,
                                loading: false,
                                fetched: false,
                                preview: null
                            }
                            if (!element.isDone) {
                                if (!element.editParent) {
                                    let pupilFile = appointment.pupilUpload.filter(upload => upload.uploader === this.account && upload.editParent === element._id);
                                    if (pupilFile && pupilFile.length > 0) {
                                        previewData.fileId = pupilFile[0].file;
                                        element.previewData = previewData;
                                    } else {
                                        previewData = null;
                                    }
                                }
                                this.babyFilesDone.push(element);
                                this.allBabyFiles.push(element);
                            }
                        }
                    }
                }
                await this.sliceLists();

                let todoBadgeAmount = 0;
                this.babyFilesToDoDisplay.forEach((babyFile) => {
                    const todoBadges = babyFile.items.filter((item) => !item.seenByMe && (item.seenBy ? !item.seenBy.includes(this.accountId) : true));
                    if (todoBadges.length) {
                        todoBadgeAmount += todoBadges.length;
                    }
                });
                this.$emit('todoBadges', todoBadgeAmount);

                let doneBadgeAmount = 0;
                this.babyFilesDoneDisplay.forEach((babyFile) => {
                    const doneBadges = babyFile.items.filter((item) => !item.seenByMe);
                    if (doneBadges.length) {
                        doneBadgeAmount += doneBadges.length;
                    }
                });
                this.$emit('doneBadges', doneBadgeAmount);


                let correctedBadgeAmount = 0;
                this.babyFilesCorrectedDisplay.forEach((babyFile) => {
                    const correctionBadges = babyFile.items.filter((item) => !item.seenByMe && (item.seenBy ? !item.seenBy.includes(this.accountId) : true));
                    if (correctionBadges.length) {
                        correctedBadgeAmount += correctionBadges.length;
                    }
                });
                this.$emit('correctionBadge', correctedBadgeAmount);
                this.showLoading = false;
            });
        },
        async fetchPreview(element, isTeacherUpload) {
            // Check if the element even has a preview and if it hasn't been fetched yet
            if (!element.previewData || element.previewData.fetched || element.previewData.loading)
                return;
            element.previewData.loading = true;
            try {

                let res;
                if (isTeacherUpload) {
                    // Fetch teacher upload
                    res = await backend.getTeacherUploadThumbnail(element.appointmentId, element.previewData.fileId);
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        element.previewData.preview = window.URL.createObjectURL(blob);
                    }
                } else {
                    // Fetch pupil upload
                    if (element.title.endsWith('.pdf')) {
                        res = await backend.getPupilUpload(element.appointmentId, element.previewData.fileId);
                    } else {
                        res = await backend.getPupilUploadThumbnail(element.appointmentId, element.previewData.fileId);
                    }
                    if (res.ok && res.status === 200) {
                        let blob = await res.blob();
                        element.previewData.preview = window.URL.createObjectURL(blob);
                    }
                }
            } catch (e) {
                console.error(e);
            }
            // Set fetched to true, to not fetch it again
            element.previewData.loading = false;
            element.previewData.fetched = true;

            // Sorry: I tried it with keys and a subcomponents, but nothing worked, this is the only thing that forces the list to update...
            this.$forceUpdate();
        },
        isOpen(appointment, element) {
            if (this.wasMovedByMe(element)) {
                return false;
            }
            /** Needs explicit check for false (for now) because ! could also mean not defined */
            if (element.isAssignment === false && element.seenByMe) {
                return false;
            }

            const checkingLeafs = appointment.pupilUpload.filter(
                upload => upload.uploader === this.account && element.editLeafs.includes(upload._id)
            );

            return checkingLeafs.length === 0 && !element.editParent;

        },
        async toggleArchiveElement(item, category) {
            // use item._id incase its a File from vfs. then item will already be a filemodel.
            const res = await backend.postSetFileInArchive(item.file ? item.file : item._id, { set: !item.archived });
            if (res.status === 200) {
                // todo: archive.homeworkFiles
                const newStatus = !item.archived;
                if (newStatus) {
                    // item is now archived, push to archive model
                    this.archive.homeworkFiles.push(item.file ? item.file : item._id);
                } else {
                    // item is restored from archive, pull from archive model
                    this.archive.homeworkFiles = this.archive.homeworkFiles.filter((el) => el != (item.file ? item.file : item._id));
                }
                switch (category) {
                    case 'done':
                        this.babyFilesDone = this.babyFilesDone.map((el) => {
                            if (el._id == item._id) {
                                el.archived = newStatus;
                            }
                            return el;
                        });
                        this.babyFilesDoneDisplay = this.babyFilesDoneDisplay.map((set) => {
                            if (set.items) {
                                set.items = set.items.map((el) => {
                                    if (el._id == item._id) {
                                        el.archived = newStatus;
                                    }
                                    return el;
                                });
                            }
                            return set;
                        });
                        break;
                    case 'corrected':
                        this.babyFilesCorrected = this.babyFilesCorrected.map((el) => {
                            if (el._id == item._id) {
                                el.archived = newStatus;
                            }
                            return el;
                        });
                        this.babyFilesCorrectedDisplay = this.babyFilesCorrectedDisplay.map((set) => {
                            if (set.items) {
                                set.items = set.items.map((el) => {
                                    if (el._id == item._id) {
                                        el.archived = newStatus;
                                    }
                                    return el;
                                });
                            }
                            return set;
                        });
                        break;
                    default:
                        console.log('missing category toggleArchiveElement', item, category);
                        break;
                }
                if (newStatus) {
                    await this.sliceLists();
                }
            }
        },

        async clickDownloadPupilUpload(file) {
            // find pupil upload
            const neededAppointment = this.appointments.filter(app => app._id === file.appointmentId)[0];

            const neededPupilUpload = neededAppointment.pupilUpload.filter(
                upload => upload.uploader === this.account && file.editLeafs.includes(upload._id)
            )[0];

            const res = await backend.getPupilUpload(file.appointmentId, neededPupilUpload.file);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                })
            })
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = neededPupilUpload.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                });
        },

        handleOpenAnsichtClick(element) {
            const neededAppointment = this.appointments.filter(app => app._id === element.appointmentId)[0];

            if (element.editParent && !element.editLeafs.length || !element.isAssignment) {
                this.openAnsicht(neededAppointment, element.title, element.file, element._id, 'pupil');
            } else {
                const pupilFile = neededAppointment.pupilUpload.filter(
                    item =>
                        (element.editLeafs
                            && element.editLeafs.includes(item._id))
                        && item.uploader === this.account
                )[0];
                if (pupilFile) {
                    this.openAnsicht(neededAppointment, pupilFile.title, pupilFile.file, pupilFile._id, 'teacher')
                } else {
                    this.openAnsicht(neededAppointment, element.title, element.file, element._id, 'pupil');
                }
            }
        },

        // called by clicking a vfs-task which is presented in homeworkcontainer
        openVfsTask(item) {
            let params = {};
            if (this.accountRole === 'pupil') {
                // params get parsed to the open-Editor call, this sets the route-query up to inform the editor
                params.sharing = this.tab == 'toDo';
                // parse the current vfs to the store and let editor retrieve relevant information
                // currently disabled but seems to work for the purpose of editing tasks (not fully tested)
                // this.setVfs(this.vfs);
            }
            // branch: can be opened in Libre or in Editor
            if (allowedMimeTypesForLibre.includes(item.mimetype)) {
                this.openLibreEditor(item);
                return;
            }
            // not sure if we will handle drafts in homework, TODO
            // if (this.previewSelection.type === 'draft') {
            //     const foundCompleteDraftVFSEntry = this.vfs.drafts
            //         .find(entry => entry.draft._id === this.previewSelection._id);
            //     this.setOriginalFileIdForVFSDraft(foundCompleteDraftVFSEntry.originalFile);
            // }
            // previewSelection -> filemodel
            this.$emit('viewFile', item, params, false, false);
        },

        async openLibreEditor(item) {
            // accountRole check not necessarily since its pupil only, but future proofing
            if (this.accountRole !== 'teacher') {
                let res = await backend.getCopyOfFile(item._id, item.accessToken);
                const copyOfFile = await res.json();
                item = copyOfFile;
                this.setLibreFileId(item._id);
                this.setLibreIsTask(true);
            }
            let fileAccessToken = item.accessToken;
            const libreDoc = await backend.getLibreDocFromFile(item._id);
            const libreDocJson = await libreDoc.json();
            this.libreDocId = libreDocJson.libreDoc._id;
            this.libreAccessToken = libreDocJson.accessToken;
            const fileType = await this.getFileExtension(item.mimetype);
            const query = {
                ...this.$route.query,
                fileId: item._id,
                libreDocId: this.libreDocId,
                accessToken: this.libreAccessToken,
                fileAccessToken,
                isTemplate: false,
                fileType: fileType,
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },

        openAnsicht(appointment, name, id, uploadId, userGroup) {
            const group = appointment.schoolSubject.name;
            const color = appointment.schoolSubject.color;
            const appointmentId = appointment._id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'viewer' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: name,
                        fId: id,
                        uploadid: uploadId,
                        ug: userGroup
                    },
            });
        },

        pupilUploadFile(item) {

            let neededAppointment = this.appointments.filter(app => app._id === item.appointmentId)[0];

            if (!neededAppointment) {
                neededAppointment = {};
                neededAppointment._id = item.appointmentId;
            }

            this.toggleOpenAppointment(neededAppointment);
            this.toggleCurrentUploadGroup('baby');
            this.toggleOpenTeacherUploadId(item.file);
            document.getElementById("uploadInput").click();
        },

        async pupilUploadInputChange(appointmentId, teacherFileId) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                // this.uploadFileName = fileName;
                // this.fileNameCallback = (newFileName) => {
                //     this.showNameFile = false;
                // };
                // this.showNameFile = true;
                await this.uploadFile(appointmentId, teacherFileId, fileName, fileExtension);
            }
        },

        async uploadFile(appointmentId, teacherFileId, fileName, fileExtension) {
            const input = this.$refs.hausiPupilUploadInput;
            const file = input.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);
                this.pupilXmlHttpRequest = backend.postTeacherUploadEdit(appointmentId, teacherFileId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', async (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({message: 'Gespeichert!'});
                    await this.requestAppointments();
                    this.pupilXmlHttpRequest = null;
                    this.$refs.hausiPupilUploadInput.value = '';
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        async moveUploadToDone(file) {
            await backend.postTeacherUploadMove(file.appointmentId, file._id);
            await this.getAllTeacherFiles(true);
            this.elementToMove = null;
            this.moveDialog = false;
        },

        wasMovedByMe(teacherupload) {
            if (teacherupload.isMoved) {
                for (let i = 0; i < teacherupload.isMoved.length; i++) {
                    if (teacherupload.isMoved[i] === this.account) {
                        return true;
                    }
                }
            }
            return false;
        },

        async toggleShowArchive() {
            this.showArchiveRequested = !this.showArchive;
            await this.sliceLists();
            this.showArchive = !this.showArchive;
        },

        // babyFilesDone, babyFilesCorrected filter out older elements and archived ones
        applyFilterToCategory(category, serverDateToday) {
            return category.map((set) => {
                set.items = set.items.map((el) => {
                    if (this.archive.homeworkFiles.includes(el.file ? el.file : el._id)) {
                        if (this.showArchiveRequested) {
                            el.archived = true;
                            return el;
                        }
                        return null;
                    }
                    el.archived = false;
                    return el;
                }).filter((set) => set);
                if (set.day) {
                    const setDate = new Date(`${set.day}`);
                    const diffDays = (serverDateToday.getTime()-setDate.getTime())/86400000;
                    return ((diffDays < 8) || (this.showArchiveRequested)) ? set : null;
                }
                // if no date is present, its vfs tasks and should be returned
                return set;
            }).filter((set) => set && set.items?.length);
        },

        /**
         * method sorts babyFiles by appointment day and fills display arrays with that
         */
        async sliceLists() {
            // get todays date into needed format
            let todayResponse = await backend.getServerTime(null);
            const todayResJson = await todayResponse.json();
            let today = new Date(todayResJson);
            let todayForArray = new Date(todayResJson);
            // avoid summer/wintertime issues
            today = today.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'});
            this.babyFilesToDoDisplay = await this.babyFilesToDo
                .filter(item => (!('canPip' in item) || item.canPip))
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, []);
            const isTodayInToDoArray = this.babyFilesToDoDisplay.find((dateSet) => {
                const date = new Date(dateSet.day);
                return date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit'}) === today;
            });
            if (!isTodayInToDoArray) {
                this.babyFilesToDoDisplay.unshift({
                    day: todayForArray,
                    items: []
                });
            }
            this.babyFilesToDoDisplay.sort(this.compareFunction);
            this.babyFilesToDoDisplay = this.applyFilterToCategory(this.babyFilesToDoDisplay, todayForArray);

            this.babyFilesDoneDisplay = this.babyFilesDone
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, [])
                .sort(this.compareFunction);
            this.babyFilesDoneDisplay = this.applyFilterToCategory(this.babyFilesDoneDisplay, todayForArray);

            this.babyFilesCorrectedDisplay = this.babyFilesCorrected
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, [])
                .sort(this.compareFunction);
            this.babyFilesCorrectedDisplay = this.applyFilterToCategory(this.babyFilesCorrectedDisplay, todayForArray);

            this.allBabyFilesDisplay = this.allBabyFiles
                .reduce((previous, currentItem) => {
                    const dateSet = previous.find(set => set.day === currentItem.day);
                    if (dateSet) {
                        dateSet.items.push(currentItem);
                    } else {
                        previous.push({
                            day: currentItem.day,
                            items: [currentItem]
                        });
                    }
                    return [...previous];
                }, [])
                .sort(this.compareFunction);

            // Functions triggered after homework load
            await this.checkImagePreviews();
            this.$emit('setTotalBadges', this.homeworkBadgeEvents);
            // if (this.windowWidth > 900) {
            //     setTimeout(() => {
            //         this.$refs[`hausiItems${this.tab}${today}`][0]
            //             .scrollIntoView(true, { behavior: 'smooth', block: 'center', inline: 'center' });
            //     }, 2000);
            // }
        },

        // used from badge, scrolls to needed upload and highlights it
        // eslint-disable-next-line
        async goToUpload(uploadData, category) {
            this.tab = category ? category : uploadData.category;
            await this.$nextTick(() => {
                const element = this.$refs[`homework${uploadData.item}`][0];
                element.$el.scrollIntoView({
                    behaviour: 'smooth',
                    block: 'end',
                });
                this.highlightHomework(element.$el);

            });
        },

        async checkImagePreviews() {
            if (this.tab === 'toDo') {
                this.babyFilesToDoDisplay.forEach(data =>
                    data.items
                        .filter(value =>
                            value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                        .forEach(value => this.fetchPreview(value, true)));
            } else if (this.tab === 'done') {
                this.babyFilesDoneDisplay.forEach(data =>
                    data.items
                        .filter(value =>
                            value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                        .forEach(value => this.fetchPreview(value, false)));
            } else if (this.tab === 'corrected') {
                this.babyFilesCorrectedDisplay.forEach(data =>
                    data.items
                        .filter(value =>
                            value.previewData && !value.previewData.fetched && !value.previewData.loading && !value.previewData.preview)
                        .forEach(value => this.fetchPreview(value, true)));
            }
        },
        async play(file) {
            // this.setFocusOn("videoPlayer");
            file.seenByMe = true;
            this.videoLoading = true;
            if (file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            let pipFile = null;
            if (file.pipReference)
                pipFile = this.babyFilesToDo.find(value => value._id === file.pipReference);

            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(file.appointmentId, file.file._id);
                const secondary = await backend.getTeacherUpload(file.appointmentId, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(file.appointmentId, file.file._id ? file.file._id : file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
            this.videoLoading = false;
        },
        async playAudio(file) {
            // this.setFocusOn("audioPlayer");
            file.seenByMe = true;
            this.videoLoading = true;
            const res = await backend.getTeacherUpload(file.appointmentId, file.file);
            const blob = await res.blob();
            this.audioUrl = window.URL.createObjectURL(blob);
            this.videoLoading = false;

        },
        setFocusOn(element) {
            document.getElementById(element).focus();
        },

        /**
         * Function setzt Fokus wenn Tab Taste gedrückt wird.
         * Bitte beachten: Fokus muss immer auf das Element zuvor gesetzt werden, weil mit Tab noch direkt ein Element
         * nach vorne gesprungen wird.
         * Function sets focus on tabpress
         * ! Focus always has to be set on the previous element since a tabpress will be executed at the same time this
         * function ist called
         * @param element elementId to set focus on if array isn't empty
         * @param tab HausiWidgetTab
         */
        setFocusOnFirstItemTab(element, tab) {
            this.tab = tab;
            switch (tab) {
                case "toDo":
                    if (this.babyFilesToDoDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if (el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                case  "done":
                    if (this.babyFilesDoneDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if (el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                case "corrected":
                    if (this.babyFilesCorrectedDisplay.length > 0) {
                        const el = document.getElementById(element);
                        if (el !== null) {
                            el.focus();
                        }
                    } else {
                        document.getElementById("correctedTab").focus();
                    }
                    break;
                default:
                    break;
            }
        },
        handleArrowKeyLeft() {
            if (!this.keyboard) {
                switch (this.tab) {
                    case  "done":
                        this.setFocusOn("toDoTab");
                        this.tab = "toDo";
                        break;
                    case "corrected":
                        this.setFocusOn("doneTab");
                        this.tab = "done";
                        break;
                    default:
                        break;
                }
            }
        },
        handleArrowKeyRight() {
            if (!this.keyboard) {
                switch (this.tab) {
                    case  "toDo":
                        this.setFocusOn("doneTab");
                        this.tab = "done";
                        break;
                    case "done":
                        this.setFocusOn("correctedTab");
                        this.tab = "corrected";
                        break;
                    default:
                        break;
                }
            }
        },

        activateTranslationBtn(index, category){
            const element = this.$refs[`speaker${category}${index}`][0];
            if (element) {
                element.classList.remove('showSpeaker');

                setTimeout(() => {
                    element.classList.add('showSpeaker');
                }, 3000);
            }
        },


        async readText(item, event) {
            let txt;
            let text;
            if (item === 'Invalid Date') {
                text = 'Aufgaben aus Dateien';
            } else {
                text = this.sanitizeHtml(this.urlify(item));
            }

            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
            }, () => {
                event.target.src = lautsprecherIcon;
            });
        },
        async openLearningPath(item){
            this.$emit('openLearningPath',item)
        }
    },
};
</script>

<style lang="scss" scoped>
.showSpeaker{
    display: none;
    z-index: 100 !important;
    position: absolute;
}

table, th, td {
    border: none;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

th {
    vertical-align: top;
}

table tr th:first-child,
table tr td:first-child {
    padding-top: 10px;
    width: 10px !important;
}

table tr th:nth-child(2),
table tr td:nth-child(2) {
    width: 5px !important;
}

table tr th:last-child,
table tr td:last-child {
    width: 5px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table, tr, td {
    border: none;
}

#hausiWidgetBabyContainer {
    height: auto;
    width: 90%;
    min-height: 80vh;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto auto 5em;
    background-color: white;
}

#hausiWidgetHeader {
    background-color: #F49F31;
    border-radius: 15px 15px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: white;
}

.fach {
    cursor: pointer;
}

.homeworkListHeader {
    width: 100%;
    float: left;
}

.homeworkListItem {
    width: 50%;
    float: left;
}

.recycleItem {
    width: 100%;
}

.scroller {
    height: 100%;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: calc(80vh - 150px);
    height: calc(80vh - 150px);
    overflow: hidden;
}

.fileRow {
    /*margin: 0.5em auto 0.5em auto;*/
    background-color: rgb(112 112 112 / 15%);
    //border-radius: 5px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    height: 76px !important;
}

.emptyRow {
    background-color: transparent;
    border-radius: 5px;

    font-size: 25px;

    & p {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
}

.brighterFileRow {
    background-color: rgb(214 214 214 / 15%);
}

.disabledFileRow {
    opacity: 0.5;
}

.fileTitle {
    margin-bottom: 0 !important;
    font-size: 25px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.babyViewActionBtn {
    min-width: 50px !important;
    width: 50px !important;
    height: 50px !important;
}

.fachLine {
    display: none;
}

.sectionHeaderText {
    color: #F49F31;
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 0;
    display: inline-block;
}

.sectionHeaderCol {
    padding-top: 0;
    padding-bottom: 0;
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;
    padding-top: 5px;
    padding-bottom: 5px;

    & button {
        width: 100%;
        padding: 25px 10px !important;
    }
}

@media screen and (max-width: 600px) {
    .sectionHeaderText {
        font-size: 24px;
    }

    .sectionHeaderImg {
        height: 24px !important;
    }

    .babyViewActionBtn {
        margin-left: 4px !important;
        min-width: 30px !important;
        width: 30px !important;
        height: 30px !important;
    }

    .babyViewActionBtnImg {
        max-width: 24px !important;
        max-height: 24px !important;
        width: 24px !important;
        height: 24px !important;
    }

    .fachLogo {
        display: none;
    }

    .fachLine {
        display: block;
        min-width: 10px;
        max-width: 10px;
        height: 100%;
        border-radius: 5px 0 0 5px;
    }

    .fileRow {
        height: 40px !important;
    }

    .fileTitle {
        font-size: 16px;
    }
}

.smallBabyViewActionBtn {
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    border-radius: 5px;
    box-shadow: 1px 2px 3px silver;
}

.babyViewActionBtnImg {
    width: 35px !important;
    height: 35px !important;
}

.smallBabyViewActionBtnImg {
    width: 28px !important;
    height: 28px !important;
    filter: brightness(1000%);
}

.editImg {
    filter: brightness(1000%);
}

.edit {
    background-color: var(--v-chatColor-base) !important;
}

.move {
    background-color: #F49F31 !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    height: 20px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
    .dateParagraph {
        width: 84% !important;
    }
}

@media only screen and (max-width: 349px) {
    .dateParagraph {
        width: 178% !important;
    }
}
.badgeEventVisualize {
    animation: badgeAppointmentRipple 5000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    20% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    80% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}


.learning-path-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%; 
    align-items: flex-start;
    margin-left: 0.5rem;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.4) inset;
    cursor: pointer;
}

.learning-path-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.folder-display {
    width: 60%; 
    height: auto;
    padding-top: 30px; 
}

.learning-path-name {
    font-size: 16px; 
}
.learning-path-button {
    margin-top: 20px;
    min-width: 40px !important;
    width: 40px !important;
    max-width: 40px !important;
     min-height: 40px !important;
    height: 40px !important;
    max-height: 40px !important;
    padding: 0px;
    cursor: pointer; /* Pointer cursor on hover */
}
</style>
