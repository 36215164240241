<template>
    <div style="width: 100%">
        <progress-bar
            title="Hochladen"
            :show-progress="showPupilUploadProgress"
            :progress="pupilUploadProgress"
            :abort-progress="pupilXmlHttpRequest ? () => { pupilXmlHttpRequest.abort() }: () => {}"
            :header-color="'var(--v-timetableColor-base)'"
        />

        <NameFile
            v-model="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
        />

        <!--menu that opens on click on fach in table-->
        <v-card
            role="dialog"
            tile
            :flat="!isPopup"
            :style="isPopup ? '' : 'background-color: unset'"
            style="color: var(--v-dunkelgrau-base)"
        >
            <v-card-title
                v-if="isPopup"
                class="pa-0 ma-0"
                style="z-index: 1"
            >
                <div
                    class="d-flex"
                    style="box-shadow: 0 5px 5px 0 rgba(0,0,0,0.3); font-size: large; align-items: center; padding-right: 20px; height: 59px; width: 100%; cursor: pointer"
                >
                    <v-col
                        cols="6"
                        class="d-flex align-center pl-0"
                    >
                        <img
                            alt=""
                            :src="getColorIcon()"
                            style="position: absolute; right: 20%; top: 0; height: 25px;"
                        >
                        <img
                            v-if="getSrc()"
                            alt=""
                            class="iconSelect"
                            :src="hasUploadedIcon ? getSrc() : getSrcWhite()"
                            style="margin-bottom: 0"
                            :style="getBackgroundColor()"
                        >
                        <div class="pl-2">
                            <p
                                :id="'fachName-' + appointment._id"
                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                class="mb-0"
                            >
                                {{ appointment.schoolSubject.name }}
                            </p>
                        </div>
                    </v-col>

                    <v-col
                        cols="4"
                        class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                        :style="`font-size: ${windowWidth < 400 ? 'small': 'medium'}`"
                    />

                    <v-col
                        cols="2"
                        align-self="end"
                        :class="windowWidth >= 2560 ? 'd-flex flex-row-reverse' : ''"
                    >
                        <v-btn
                            :tabindex="planVollbild ? tabOrderPopup + 1 : '3'"
                            elevation="2"
                            x-small
                            class="ml-2 pa-0 btnStyle"
                            style="width: 30px; height: 30px"
                            @click="$emit('closeMenu')"
                        >
                            <img
                                :src="schliesenIcon"
                                alt="Schließen"
                                style="height: 20px"
                            >
                        </v-btn>
                    </v-col>
                </div>
            </v-card-title>


            <div
                class="scroll-area-fachPopup mb-2"
                data-simplebar
            >
                <!--                :settings="settings"-->
                <!-- Info area -->
                <div
                    :class="isPopup ? 'mx-5' : 'whiteBackgroundTile my-4'"
                    @click="showSpeakerBtn()"
                >
                    <div :class="isPopup ? 'mt-2' : ''">
                        <v-row>
                            <v-col class="font-weight-bold py-2 d-flex align-center">
                                <img
                                    :src="infoIcon"
                                    style="height: 20px;"
                                    alt=""
                                >
                                <p
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    style="width: fit-content; float: right; font-size: medium"
                                    class="ml-2 ma-0"
                                >
                                    Fachinfo
                                </p>
                            </v-col>
                            <v-col class="text-right mr-10 py-1">
                                <div
                                    :ref="`speaker`"
                                    class="showSpeaker"
                                >
                                    <v-btn
                                        :aria-hidden="person && person.accessibility && person.accessibility.screenreader"
                                        :tabindex="planVollbild ? tabOrderPopup : '1'"
                                        elevation="2"
                                        absolute
                                        x-small
                                        class="btnStyle"
                                        @click="greet($event)"
                                    >
                                        <img
                                            alt="Vorlesen"
                                            :src="lautsprecherIcon"
                                            style="height: 20px;"
                                        >
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                        <!-- eslint-disable vue/no-v-html -->
                        <div
                            v-if="diaryNote || materialTeacherUploadsToDisplayWithUserNote().length > 0"
                            class="appointmentNoteText"
                        >
                            <!-- <div
                                data-simplebar
                                settings="settings"
                                style="font-size: medium; font-weight: normal; line-height: 25px; cursor: pointer"
                                class="mb-4 mt-1 scroll-area"
                            > -->
                            <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                            <!-- eslint-disable vue/no-v-html -->
                            <p
                                v-if="diaryNote"
                                class=" px-2 py-1"
                                v-html="sanitizeHtml(urlify(diaryNote))"
                            />
                            <!-- eslint-enable vue/no-v-html -->
                            <!-- </div> -->
                            <!-- <p
                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                class="ql-editor mb-0"
                                style="overflow-y: auto; font-size: medium"
                                v-html="sanitizeHtml(urlify(appointment.note))"
                            /> -->
                            <span
                                v-for="(upload,index) in materialTeacherUploadsToDisplayWithUserNote()"
                                :key="index"
                            >
                                {{ "* Notiz zu  " + upload.title + ": " + upload.userNotes.find((el) => el.account === person.account).note }}
                            </span>
                        </div>
                        <!-- eslint-enable vue/no-v-html -->
                        <div
                            v-else
                            class="appointmentNoteText pa-2"
                        >
                            <p
                                :tabindex="planVollbild ? tabOrderPopup : '1'"
                                class="mb-0"
                            >
                                Es liegt keine Fachinfo für diese Stunde vor.
                            </p>
                        </div>
                    </div>
                </div>

                <hr
                    v-if="isPopup"
                    aria-hidden="true"
                    class="eHr"
                >

                <!-- Material area -->
                <div
                    v-if="!babyView"
                >
                    <div
                        :class="isPopup ? 'mx-5' : 'whiteBackgroundTile'"
                        class="mb-4"
                    >
                        <div class="mt-2">
                            <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between">
                                <div class="font-weight-bold d-flex align-center justify-space-between">
                                    <div class="d-flex">
                                        <img
                                            :src="materialIcon"
                                            style="height: 20px;"
                                            alt=""
                                        >
                                        <p
                                            :tabindex="planVollbild ? tabOrderPopup : '1'"
                                            style="width: fit-content; float: right; font-size: medium"
                                            class="ml-2 ma-0"
                                        >
                                            Aufgaben
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <FileListViewChangeButton
                                        :list-view="listViewMaterial"
                                        @changeListView="(view) => { changeListViewMaterial(view) }"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="appointment && appointment.teacherUploads.filter(item => !wasMovedByMe(item)).length === 0"
                                class="pa-2"
                                style="font-size: medium; background-color: rgba(112,112,112, 0.15); border-radius: 8px"
                            >
                                <p
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    class="mb-0"
                                >
                                    Es wurden keine Aufgaben hochgeladen.
                                </p>
                            </div>

                            <!-- Material list -->
                            <FileList
                                v-else
                                :files="materialTeacherUploadsToDisplay()"
                                :preview-urls="urls"
                                :view="listViewMaterial"
                                :primary-color="'#706f6f'"
                                :person="person"
                                :account-role="'pupil'"

                                @thumbnailClicked="(file) => { openFilePreview(file, true, true) }"
                                @playVideo="(file) => { play(file) }"
                                @playAudio="(file) => { playAudio(file) }"
                                @editInEditor="(file) => { alreadyEditedByMe(file) ? openAlreadyEditedDialog(file) : openEdit(file.title, file.file, file._id, file.uploader, file) }"
                                @editInLibre="(file) => { openLibreEditor(file) }"
                                @doneFile="(file) => { moveDialog = true; elementToMove = file }"
                                @uploadAnswer="(file) => { pupilUploadFileAsAnswer(file) }"
                                @downloadMainVideo="(file) => { clickDownloadTeacherUpload(file) }"
                                @downloadDGSVideo="(file) => { clickDownloadTeacherUpload(appointment.teacherUploads.find(value => value._id === file.pipReference)) }"
                                @downloadFile="(file) => { clickDownloadTeacherUpload(file) }"
                            />
                        </div>
                    </div>

                    <hr
                        v-if="isPopup"
                        aria-hidden="true"
                        class="eHr"
                    >

                    <!-- Abgaben area -->
                    <div
                        :class="isPopup ? 'mx-5' : 'whiteBackgroundTile'"
                    >
                        <div class="d-flex flex-column mt-2">
                            <div
                                class="d-flex justify-space-between align-center"
                                style="align-items: flex-end"
                            >
                                <div class="font-weight-bold pb-2 pt-0 d-flex align-center">
                                    <img
                                        :src="abgabenIcon"
                                        alt=""
                                        style="height: 20px;"
                                    >
                                    <p
                                        :tabindex="planVollbild ? tabOrderPopup : '1'"
                                        class="ml-2 ma-0"
                                        style="width: fit-content; float: right; font-size: medium"
                                    >
                                        Abgaben
                                    </p>
                                </div>

                                <div class="d-flex justify-start align-center">
                                    <AudioUpload
                                        v-if="appointment"
                                        :tab-order="planVollbild ? tabOrderPopup : '1'"
                                        :upload="uploadAudioFile"
                                        :appointment-id="appointment._id"
                                    />

                                    <FileListViewChangeButton
                                        :list-view="listViewAbgaben"
                                        @changeListView="(view) => { changeListViewAbgaben(view) }"
                                    />

                                    <v-menu
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                elevation="0"
                                                style="background-color: transparent; min-width: 20px; max-width: 25px;"
                                                v-bind="attrs"
                                                aria-label="Datei hochladen, unterstütze Formate, welche als Abgabe hochgeladen werden können: Audio: AAC, MP3, M4A, OGG, WAV, WMA. Bilder oder Texte: BMP, JPG, PDF, PNG, TIFF. Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV. MS Office Dateien: DOCX, PPTX, XLSX, TXT, etc."
                                                v-on="on"
                                            >
                                                <img
                                                    :src="plusIcon"
                                                    class="square20 iconToDunkelgrau"
                                                    alt="Plus"
                                                >
                                            </v-btn>
                                        </template>
                                        <div class="small-drop-down-menu">
                                            <v-btn
                                                tabindex="0"
                                                style="text-transform: inherit; color: white; background-color: var(--v-headerblue-base)"
                                                text
                                                class="d-flex justify-start"
                                                @click="openEmptyEdit('empty')"
                                            >
                                                <img
                                                    :src="neuesABIcon"
                                                    class="iconSize mr-1"
                                                    style="filter: brightness(1000%);"
                                                >
                                                Datei im Editor erstellen
                                            </v-btn>
                                            <!-- <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                            text
                                            class="d-flex justify-start"
                                            @click="() => { fileWidgetUploadDialog = true; }"
                                        >
                                            <img
                                                :src="eklaraDateiIcon"
                                                class="iconSize mr-1"
                                            >
                                            Datei aus eKlara hochladen
                                        </v-btn> -->
                                            <v-btn
                                                tabindex="0"
                                                style="text-transform: inherit; color: var(--v-dunkelgrau-base)"
                                                text
                                                class="d-flex justify-start"
                                                @click="clickPupilUploadButton"
                                            >
                                                <img
                                                    :src="dateiIcon"
                                                    class="iconSize mr-1"
                                                    alt="Datei hochladen"
                                                >
                                                Foto, Datei hochladen
                                            </v-btn>
                                        </div>
                                    </v-menu>
                                </div>
                            </div>

                            <div class="text-right py-1">
                                <input
                                    id="pupilUploadInput"
                                    ref="pupilUploadInput"
                                    type="file"
                                    hidden
                                    @change="() => pupilUploadInputChange(appointment._id)"
                                >
                            </div>

                            <div
                                v-if="noSubmissionsGiven"
                                :id="`submisionsAppointment${appointment._id || appointment}`"
                                class="mx-5"
                                style="font-size: medium; color: grey;"
                            >
                                <p
                                    :tabindex="planVollbild ? tabOrderPopup : '1'"
                                    class="mb-0"
                                >
                                    {{ 'Du hast noch nichts abgegeben. ' + pupilUploadText }}
                                </p>
                            </div>

                            <div
                                v-else
                                :id="`draftsAppointment${appointment._id || appointment}`"
                            >
                                <FileList
                                    :usage="'abgabenPupil'"
                                    :files="abgabenPupilUploadsToDisplay"
                                    :preview-urls="urls"
                                    :view="listViewAbgaben"
                                    :primary-color="'#706f6f'"
                                    :person="person"
                                    :account-role="'pupil'"
                                    :appointment="appointment"

                                    @thumbnailClicked="(file) => { openFilePreview(file, false, false) }"
                                    @playVideo="(file) => { file.isMoved && file.isMoved.length ? play(file) : playPupil(file) }"
                                    @playAudio="(file) => { file.isMoved && file.isMoved.length ? playAudio(file) : playAudioPupil(file) }"
                                    @downloadFile="(file) => { clickDownloadTeacherUpload(file) }"
                                    @openInEditor="(file) => { handleOpenAnsichtClick(file, file.isMoved) }"
                                    @openInLibre=" (file) => { openLibreEditor(file.correction, true) }"
                                    @editDraft="(file) => { openDraftForEdit(file, 'pupil') }"
                                    @deleteDraft="(file) => { deleteDraft(file.draft._id) }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>

        <v-dialog
            v-if="alreadyEditedDialog"
            v-model="alreadyEditedDialog"
            max-width="350px"
            content-class="rounded-4"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: var(--v-timetableColor-base)"
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Nochmal bearbeiten?
                    </p>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                color="transparent"
                                elevation="0"
                                style="margin-right: -8px"
                                v-bind="attrs"
                                v-on="on"
                                @click="alreadyEditedDialog = false"
                            >
                                <img
                                    :src="schliesenIcon"
                                    class="icon30 iconToWhite"
                                    alt="schließen"
                                >
                            </v-btn>
                        </template>
                        <span>Dialog schließen</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    Du hast diese Aufgabe bereits bearbeitet und abgegeben. Wenn du sie nochmal bearbeitest hat der
                    Lehrer mehrere Abgaben von dir. Willst du die Aufgabe wirklich nochmal bearbeiten?
                </v-card-text>
                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="#F5F5F5"
                        @click="alreadyEditedDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20 mr-2"
                            alt="abbrechen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="gruen"
                        dark
                        @click="openEdit(alreadyEditedUpload.title, alreadyEditedUpload.file, alreadyEditedUpload._id, alreadyEditedUpload.uploader, alreadyEditedUpload)"
                    >
                        <img
                            :src="bearbeitenIcon"
                            class="icon20 mr-2 iconToWhite"
                        >
                        Bearbeiten
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Normal Video Popup -->
        <v-dialog
            v-if="videoUrl"
            :value="videoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                        :fullscreen-mode="true"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            v-if="primaryVideoUrl && secondaryVideoUrl"
            :value="primaryVideoUrl && secondaryVideoUrl"
            overlay-opacity=".9"
            overlay-color="black"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        large
                        dark
                        @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop();"
                    >
                        fas fa-times
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="videoInVideo"
                        size-controls
                        :primary-video-src="primaryVideoUrl"
                        :secondary-video-src="secondaryVideoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>
        <v-dialog
            v-if="moveDialog"
            v-model="moveDialog"
            max-width="350px"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: var(--v-timetableColor-base)"
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Abgeben bestätigen
                    </p>
                    <v-btn
                        icon
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon30 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    Willst du die Datei wirklich <strong>ohne</strong> eine Lösung/Abgabe abgeben?
                </v-card-text>
                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="#F5F5F5"
                        @click="moveDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20 mr-2"
                            alt="abbrechen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="gruen"
                        dark
                        @click="moveUploadToDone(elementToMove)"
                    >
                        <img
                            :src="fertigIcon"
                            class="icon20 mr-2 iconToWhite"
                        >
                        Abgeben
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <FilePreviewDialog
            v-if="showFilePreview"
            :enable-fullscreen-preview="showFilePreview"
            :preview-selection="previewSelection"
            :preview-information="previewSelection.file"
            :files="[...appointment.teacherUploads, ...appointment.pupilUpload]"
            :enable-editing="allowEditingForPreview"
            :person="person"
            :account-role="'pupil'"
            :fach-info="true"
            :usage="'plan'"
            @openEditor="openEdit(previewSelection.title, previewSelection.file._id, previewSelection._id, previewSelection.uploader, previewSelection)"
            @openLibre="openLibreEditor(previewSelection)"
            @download="handlePreviewDownloadClicked"

            @downloadFile="(file) => { clickDownloadTeacherUpload(file) }"

            @editInEditor="(file) => { alreadyEditedByMe(file) ? openAlreadyEditedDialog(file) : openEdit(file.title, file.file._id, file._id, file.uploader, file) }"
            @editInLibre="(file) => { openLibreEditor(file) }"
            @doneFile="(file) => { moveDialog = true; elementToMove = file }"

            @downloadMainVideo="(file) => { clickDownloadTeacherUpload(file) }"
            @downloadDGSVideo="(file) => { clickDownloadTeacherUpload(appointment.teacherUploads.find(value => value._id === file.pipReference)) }"
            @uploadAnswer="(file) => { pupilUploadFileAsAnswer(file) }"

            @openInEditor="(file) => { handleOpenAnsichtClick(file, file.isMoved) }"
            @editDraft="(file) => { openDraftForEdit(file, 'pupil') }"
            @deleteDraft="(file) => { deleteDraft(file.draft._id) }"

            @downloadCorrectionFile="(file) => { isCorrectionAvailable(file) ? clickDownloadPupilCorrection(file) : clickDownloadPupilUpload(file) }"
            @editCorrectionDraft="(file) => { openDraftForEdit(file, 'teacher') }"
            @close="showFilePreview = false"
        />

        <!-- Dialog for FileWidget upload -->
        <FileWidgetSelectionDialog
            :show="fileWidgetUploadDialog"
            :subject-apponintment="appointment"
            :item-selected-callback="uploadViaFileWidget"
            @close="fileWidgetUploadDialog = false;"
        />
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import sanitizeHtml from '../../util/sanitizeHtml';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor,
    allowedExtensionsForLibre,
    allowedExtensionsForVideoPlayer
} from "../../constants/allowedMimeTypes";
import {isFileThumbnailCompatibleExtension} from "../../util/MimetypeHelper.js"

import AudioUpload from "@/components/AudioUpload";
import FileWidgetSelectionDialog from "@/components/Utils/FileWidgetSelectionDialog";
import ProgressBar from "@/components/ProgressBar";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import NameFile from "@/components/NameFile";
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import FileList from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileList";
import FileListViewChangeButton
    from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListViewChangeButton";

import plusIcon from "@/assets/Icons/plus.svg";
import schliesenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import lautsprecherIcon from "@/assets/Icons/lautsprecher-13.svg";
import stiftIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import bearbeitenIcon from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import img_eklaraLogo from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';
import documentIcon from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';
import offenIcon from "@/assets/Icons/offen-18.svg";
import runterladenIcon from "@/assets/Icons/FaceliftIcons/datei_herunterladen.svg";
import playIcon from "@/assets/Icons/rechts-filled-11.svg";
import materialIcon from "@/assets/Icons/FaceliftIcons/material_dateien.svg";
import abgabenIcon from "@/assets/Icons/FaceliftIcons/hausis.svg";
import arrowDown from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import fasFaEye from "@/assets/Icons/fas-fa-eye.svg";
import kameraIcon from "@/assets/Icons/FaceliftIcons/kamera.svg";
import fertigIcon from "@/assets/Icons/fertig-74.svg";
import dateiIcon from "@/assets/Icons/FaceliftIcons/ordner_lokal.svg";
import eklaraDateiIcon from "@/assets/Icons/FaceliftIcons/ordner_eklara.svg";
import neuesABIcon from "@/assets/Icons/neues-interaktives-arbeitsblatt.svg";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import hausaufgabenMetacom from "@/assets/METACOM/Hausis/hausaufgaben.png";
import toDoMetacom from "@/assets/METACOM/Hausis/machen.png";
import lautsprecherAusIcon from "@/assets/Icons/lautsprecher-aus-89.svg";
import musicIcon from '@/assets/Icons/f-musik-84.svg';
import pdfIcon from '@/assets/Icons/FaceliftIcons/pdf_libre_fachinfo.svg';
import imageIcon from '@/assets/Icons/FaceliftIcons/bild.svg';
import wordIcon from "@/assets/Icons/FaceliftIcons/doc_libre_fachinfo.svg";
import powerpointIcon from "@/assets/Icons/FaceliftIcons/ppt_libre_fachinfo.svg";
import excelIcon from "@/assets/Icons/FaceliftIcons/xls_libre_fachinfo.svg";
import videoIcon from '@/assets/Icons/video.svg';
import optionsIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";

import {generateThumbnailForPdf} from "@/util/tempThumbnailGen";
import urlify from '../../util/urlify';


export default {
name: "FachInfoSchueler",
    components: {
        AudioUpload,
        FileWidgetSelectionDialog,
        ProgressBar,
        VideoInVideo,
        NameFile,
        FilePreviewDialog,
        FileList,
        FileListViewChangeButton,
    },
    props: {
        // Variables
        isPopup: { required: true },
        appointment: { required: true, type: Object },
        tabOrderPopup: {required: false},
        planVollbild: {required: false, default: false},
        person: {required: true},
        sehen: {required: false, default: false},
        hoeren: {required: false, default: false},
        motorik: {required: false, default: false},
        urls: {required: true},
        isJoinable: {required: true},
        hasUploadedIcon: {required: false, default: false},

        // Functions
        getSrc: {type: Function, required: true},
        getColorIcon: {type: Function, required: true},
        getSrcWhite: {type: Function, required: true},
        getBackgroundColor: {type: Function, required: true},
        requestAppointments: {required: true},

        uploadFileObject:{ default: null, required: false},

        uploadFileCallback: { type: Boolean, required: false, default: false}
    },
    data: () => ({
        sanitizeHtml,
        urlify,
        uploadLoadings: [],
        uploadLoadingsPupil: [],
        alreadyEditedDialog: false,
        alreadyEditedUpload: null,
        fileWidgetUploadDialog: false,
        showPupilUploadProgress: false,
        pupilUploadProgress: 0.0,
        pupilXmlHttpRequest: null,
        audioUrl: null,
        videoUrl: null,
        primaryVideoUrl: null,
        secondaryVideoUrl: null,
        showNameFile: false,
        uploadFileName: '',
        subtitleURL: null,
        noSubtitle: false,
        showTextTimeout: null,
        materialTeacherUploadsExpanded: false,
        abgabenPupilUploadsExpanded: false,
        pupilUploadText: 'Auf Plus-Symbol klicken oder Datei einfach hereinziehen, um Dateien hochzuladen!',

        allowedExtensionsForEditor,
        allowedExtensionsForLibre,

        showFilePreview: false,
        previewSelection: null,
        allowEditingForPreview:  false,
        isPreviewSelectionTeacherUpload: false,

        isFileThumbnailCompatibleExtension,
        fileNameCallback: () => {
        },

        listViewMaterial: 'list',
        listViewAbgaben: 'list',
        moveDialog: false,
        elementToMove: null,
        //Icons
        plusIcon,
        schliesenIcon,
        lautsprecherIcon,
        stiftIcon,
        bearbeitenIcon,
        offenIcon,
        runterladenIcon,
        playIcon,
        materialIcon,
        abgabenIcon,
        arrowDown,
        infoIcon,
        fasFaEye,
        kameraIcon,
        fertigIcon,
        dateiIcon,
        eklaraDateiIcon,
        neuesABIcon,
        herunterladenIcon,
        documentIcon,
        musicIcon,
        pdfIcon,
        imageIcon,
        wordIcon,
        powerpointIcon,
        excelIcon,
        videoIcon,
        img_eklaraLogo,
        optionsIcon,

        hausaufgabenMetacom,
        toDoMetacom,

        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false
        },

        showMoreBadge: null,
        diaryNote: null,
    }),

    computed: {
        ...mapState('translation', ['targetLang']),
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapState("tooltips", ["showTooltips"]),
        ...mapGetters('pupils', ['currentPupil']),
        ...mapState('auth', ['account']),
        ...mapGetters('util', ['currentlyOpenAppointment', 'currentUploadGroup', 'currentUploadFolder', 'currentlyOpenTeacherUploadId', 'getTeacherUploadCallback']),
        ...mapGetters("accounts", ["accountsById"]),

        abgabenPupilUploadsToDisplay() {
            if(this.appointment && this.appointment.pupilUpload) {
                let abgabenToDisplay = this.appointment.pupilUpload.concat(this.appointment.teacherUploads.filter((teacherUpload) => {
                    return teacherUpload.isMoved && teacherUpload.isMoved.length && teacherUpload.isMoved.includes(this.person.account);
                }));
                abgabenToDisplay = this.draftPupilUploadsToDisplay.concat(abgabenToDisplay);
                abgabenToDisplay.forEach((el) => {
                    el.usage = 'abgabenPupil';
                })
                return abgabenToDisplay;
            }
            return [];
        },

        draftPupilUploadsToDisplay() {
            if (this.appointment && this.appointment.drafts) {
                const allMaterialUploads = this.appointment.teacherUploads
                    .filter((item) => !item.editParent)
                    .map(item => item._id);
                const result = this.appointment.drafts
                    .filter(entry => !entry.reference || allMaterialUploads
                        .find((upload) => upload === entry.reference));
                return result;
            }
            return [];
        },
        noSubmissionsGiven() {
            return (this.appointment && this.appointment.pupilUpload
                && this.appointment.pupilUpload.length === 0)
                && !this.draftPupilUploadsToDisplay.length
                && (this.appointment && this.appointment.teacherUploads
                && this.appointment.teacherUploads.filter((teacherUpload) => {
                    return teacherUpload.isMoved && teacherUpload.isMoved.length && teacherUpload.isMoved.includes(this.person.account);
                }).length === 0)
        },
        isDesktopSize() {
            // height on my 1080p screen is about 762px - Jan
            return this.windowWidth > 900 && this.windowHeight > 700
        },
        babyView() {
            return this.currentPupil.babyView;
        }
    },
    watch: {
        uploadFileCallback(newValue){
             if(this.uploadFileCallback === true) {
                this.dragAndDropFile(this.uploadFileObject);
            }
        },
    },
    async mounted() {
        this.$emit("loadImagePreviews");
        // Checking for mobile devices
        if (!this.isDesktopSize) {
            this.pupilUploadText = 'Auf Plus-Symbol klicken, um Dateien hochzuladen!';
        }
        if(this.appointment && this.appointment.pupilUpload) {
            this.appointment.pupilUpload.map((pupilUpload) => {
                const correction = this.isCorrectionAvailable(pupilUpload)
                if(correction) {
                    this.$set(pupilUpload, 'correction', correction);
                }
            })
        }
        if(this.appointment.appointmentDiaryEntryNote){
            const appointmentDiaryEntryRes = await backend.getAppointmentDiaryEntryNote(
                this.appointment.appointmentDiaryEntryNote._id);
            const diaryNote = await appointmentDiaryEntryRes.json();

            this.diaryNote = diaryNote.text;
        }
        if(this.appointment.note !== ''){
            this.diaryNote = this.diaryNote + ' ' + this.appointment.note;
        }
    },
    beforeMount() {
        if(this.account.accountPreferences) {
            if(this.account.accountPreferences.listViewMaterial) {
                this.listViewMaterial = this.account.accountPreferences.listViewMaterial;
            }
            if(this.account.accountPreferences.listViewAbgaben) {
                this.listViewAbgaben = this.account.accountPreferences.listViewAbgaben;
            }
        }
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('mimeTypes', ['isCollaboraCompatible','getFileExtension']),
        ...mapActions("util", ['toggleOpenAppointment', 'toggleOpenTeacherUploadId', 'toggleCurrentUploadGroup', 'toggleFachInfoUploadCallback']),
        ...mapMutations('appointments', ['addOrUpdateAppointment']),
        ...mapActions('appointments', [ 'postPupilUploadViaFileId' ]),
        ...mapActions("documentDrafts", ["deleteDocumentDraft"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        async moveUploadToDone(file) {
            await backend.postTeacherUploadMove(this.appointment._id, file._id);
            this.elementToMove = null;
            this.moveDialog = false;
            await this.requestAppointments(this.appointment._id);

        },
        async changeListViewMaterial(view) {
            this.listViewMaterial = view;
            await backend.patchPreferences(this.person.account, { name: 'listViewMaterial', value: view });
        },
        async changeListViewAbgaben(view) {
            this.listViewAbgaben = view;
            await backend.patchPreferences(this.person.account, { name: 'listViewAbgaben', value: view });
        },
        handleThumbnailClicked(upload) {
            const fileExtension = upload.title.split('.').pop().toLowerCase();
            if(['png', 'jpg', 'jpeg', 'bmp'].includes(fileExtension)) {
                this.openEdit(upload.title, upload.file, upload._id, upload.uploader, upload);
            } else if (this.allowedExtensionsForLibre.includes(fileExtension)) {
                this.openLibreEditor(upload, false);
            } else if (this.allowedExtensionsForEditor.includes(fileExtension)) {
                this.openEdit(upload.title,upload.file, upload._id, upload.uploader, upload);
            } else {
                this.play(upload);
            }
        },
        setObjectPropertyReactive(object, propertyName, value) {
            this.$set(object, propertyName, value);
        },
        handlePreviewDownloadClicked() {
          if (this.isPreviewSelectionTeacherUpload) {
            this.clickDownloadTeacherUpload(this.previewSelection);
          } else if (!this.isPreviewSelectionTeacherUpload && this.allowEditingForPreview) {
            this.clickDownloadPupilUpload(this.previewSelection);
          } else {
            this.clickDownloadPupilCorrection(this.previewSelection);
          }
        },
        async openFilePreview(el, allowEdit, isTeacherUpload) {
            const isFileDeleted = this.checkForDeletion(el);
            if (isFileDeleted) return;
            if(el.editParent && typeof el.isMoved === 'boolean' && el.isMoved === true) {
                el = this.appointment.teacherUploads.find((teacherUpload) => el.editParent === teacherUpload._id );
                if(!el) {
                    this.showSnackbar({
                        message: 'Die Ursprungsdatei wurde gelöscht!',
                        color: 'error'
                    });
                }
            }
            if(allowedExtensionsForVideoPlayer.includes(el.title.split('.').pop().toLowerCase())) {
                if(el.usage === 'abgabenPupil' && !(typeof el.isMoved === 'object' && el.isMoved.includes(this.person.account))) {
                    await this.playPupil(el);
                } else {
                    await this.play(el);
                }
                return;
            } else if(allowedExtensionsForAudioPlayer.includes(el.title.split('.').pop().toLowerCase())) {
                if(el.usage === 'abgabenPupil' && !(typeof el.isMoved === 'object' && el.isMoved.includes(this.person.account))) {
                    await this.playAudioPupil(el);
                } else {
                    await this.playAudio(el);
                }
                return;
            }
          const file = el.file._id ? el.file._id : el.file;
          const res = await backend.getFile(file, el.fileAccessToken);
          const resJson = await res.json();
            // This is necessary otherwise the file property of an upload is populated and nothing works after opening the FilePreview
            this.previewSelection = JSON.parse(JSON.stringify(el));
          this.isPreviewSelectionTeacherUpload = isTeacherUpload;
          this.previewSelection.file = resJson.file;
          this.previewSelection.thumbnail = this.urls[this.previewSelection._id];
          this.allowEditingForPreview = allowEdit;
          this.showFilePreview = true;
        },
        async openLibreEditor(uploadMaterial, isCorrectur) {
            const isFileDeleted = this.checkForDeletion(uploadMaterial);
            if (isFileDeleted) return;
          const fileId = uploadMaterial.file._id ? uploadMaterial.file._id : uploadMaterial.file;
          let res;
          let uploadFile;
          if (!uploadMaterial.isTeamwork && !isCorrectur) {
              res = await backend.getCopyOfFile(fileId, uploadMaterial.fileAccessToken);
              uploadFile = await res.json();
              res = await backend.getLibreDocFromFile(uploadFile._id);
          } else {
              res = await backend.getFileInformation(fileId, uploadMaterial.fileAccessToken);
              uploadFile = await res.json();
              res = await backend.getLibreDocFromFile(fileId);
          }
          const doc = await res.json();
          const libreAccessToken = doc.accessToken;
           const libreDocId = doc.libreDoc._id;

          const file = doc.file;
          const fileType = await this.getFileExtension(file.mimetype);
          const query = {
            ... this.$route.query,

            fileId: file._id,
            libreDocId: libreDocId,
            accessToken: libreAccessToken,
            fileType: fileType,
              fileAccessToken: uploadMaterial.isTeamwork || isCorrectur ? uploadMaterial.fileAccessToken :  uploadFile.accessToken,
              parentComponent: 'plan',
            mode: 'material',
          };
          // Call this, so that the pupil gets added to seenByMe Array in backend
          backend.getTeacherUpload(this.appointment._id, fileId);

          await this.$router.push({
            name: 'libreoffice',
            query,
          });
        },

      /**
       * #Region drag and drop stuff
       */
        async dragAndDropFile(file){
            this.toggleOpenAppointment(this.appointment);
            this.toggleCurrentUploadGroup('pupil');
            await this.uploadInputChange(file[0]);

        },
        async uploadInputChange(parsedFile) {
            let files;
            if (!parsedFile) {
                // a file was set as input.value
                files = Array.from(this.$refs.uploadInput.files);

            }else {
                //a file was programmatically parsed
                files = [parsedFile];
            }

            // Create tmp variables because in uploadFile these variables get reset to null
            // and we want to call uploadFile with the initial variables
            const tmpCurrentlyOpenAppointment = this.currentlyOpenAppointment;
            const tmpCurrentGroup = this.currentUploadGroup;
            const tmpOpenTeacherUploadId = this.currentUploadFolder;
            // Upload files sequentially
            const fileUploadResults = await files.reduce(async (previousFileUploadResultsPromise, file) => {
                const previousFileUploadResults = await previousFileUploadResultsPromise;

                // Show name file widget and upload files when appropriate
                const prepareFileUpload = (file) => new Promise((resolve, reject) => {
                    if (this.isVideo(file.name)) // ToDo check Video duration
                    {
                        this.isSubtitleReady = true;
                    } else {
                        this.isSubtitleReady = false;
                    }

                    const name = file.name;
                    const lastDot = name.lastIndexOf('.');
                    const fileName = name.substring(0, lastDot);
                    const fileExtension = name.substring(lastDot + 1);
                    this.uploadFileName = fileName;


                    this.fileNameCallback = (newFileName, selectedUser, userNotes, startDate, isAssignment, createSubtitles) => {
                        this.showNameFile = false;
                        this
                            .uploadFile(file, newFileName, fileExtension, selectedUser, userNotes, startDate, isAssignment, createSubtitles, tmpCurrentlyOpenAppointment, tmpCurrentGroup, tmpOpenTeacherUploadId)
                            .then(() => {
                                if (this.currentUploadGroup === 'pupil' || this.currentUploadGroup === 'teacher') {
                                    /*this.$route.query.appointment = localStorage.getItem('reopenAppointment');
                                    localStorage.removeItem('reopenAppointment');*/
                                }
                                resolve();
                            })
                    }
                    this.showNameFile = true;
                });

                const fileUploadResult = await prepareFileUpload(file);

                return Promise.resolve([...previousFileUploadResults, fileUploadResult]);
            }, Promise.resolve([]))
        },
        async uploadFile(file,
            fileName,
            fileExtension,
            selectedUser,
            userNotes,
            startDate,
            isAssignment,
            createSubtitles,
            currentlyOpenAppointment = this.currentlyOpenAppointment,
            currentUploadGroup = this.currentUploadGroup,
            currentUploadFolder = this.currentUploadFolder,
        ) {
            const localAppointment = currentlyOpenAppointment;
            const localTeacherUploadId = this.currentlyOpenTeacherUploadId;
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);

                if (currentUploadGroup === 'teacher') {
                    formData.append('visibleFor', JSON.stringify(selectedUser));
                    formData.append('userNotes', JSON.stringify(userNotes));
                    formData.append('startDate', JSON.stringify(startDate));
                    formData.append('isAssignment', JSON.stringify(isAssignment));
                    formData.append('createSubtitles', JSON.stringify(createSubtitles));

                    this.pupilXmlHttpRequest = await backend.postTeacherUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'pupil') {
                    this.pupilXmlHttpRequest = await backend.postPupilUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'subject') {
                    this.pupilXmlHttpRequest = await backend.postSubjectFolderUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'baby' && localTeacherUploadId) {
                    this.pupilXmlHttpRequest = await backend.postTeacherUploadEdit(localAppointment._id, localTeacherUploadId, file).then((response) => {
                        if (response.status === 201) {
                            this.showSnackbar({ message: "Gespeichert!" });
                        } else {
                            this.showSnackbar({ message: "Etwas ist schiefgelaufen", color: "error" })
                        }
                        this.toggleOpenAppointment(null);
                        this.toggleCurrentUploadGroup(null);
                        this.toggleOpenTeacherUploadId(null);
                        this.pupilXmlHttpRequest = null;
                        this.$refs.uploadInput.value = '';
                    });
                } else if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder') {
                    this.pupilXmlHttpRequest = await backend.postVFSFolderUpload(file, currentUploadGroup, currentUploadFolder._id);
                }

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', async (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201) {
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                        if (this.pupilXmlHttpRequest.status === 409) {
                            this.showSnackbar({ message: "Speicher überschritten. Datei konnte nicht gespeichert werden", color: "error" });
                        }
                    }
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({ message: "Gespeichert!" });
                    this.toggleOpenAppointment(null);
                    if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder') {
                        // generate thumbnail if file is pdf
                        if (fileExtension === 'pdf') {
                            const dataForThumbnail = await generateThumbnailForPdf(
                                JSON.parse(this.pupilXmlHttpRequest.response)._id,
                                file
                            );
                            // Push thumbnail
                            const thumbnailResponse = await backend.postThumbnail(
                                dataForThumbnail.originFileId,
                                dataForThumbnail.thumbnailFile
                            );
                        }
                        // Trigger reload of vfs in fileWidget
                        this.triggerReloadOfVfs();
                    }
                    this.toggleCurrentUploadGroup(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.requestAppointments();
                    this.$emit("fileUploaded");
                    // Optional Callback set in util/teacherUploadCallback to have a callback when upload is done
                    if(this.getTeacherUploadCallback) {
                        this.getTeacherUploadCallback();
                    }
                    this.pupilXmlHttpRequest = null;
                });

                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },
        getExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        isVideo(filename) {
            var ext = this.getExtension(filename);
            switch (ext.toLowerCase()) {
                case 'm4v':
                case 'avi':
                case 'mpg':
                case 'mp4':
                case 'mkv':
                    return true;
            }
            return false;
        },

        materialTeacherUploadsToDisplay() {
            if(this.appointment && this.appointment.teacherUploads) {
                return this.appointment.teacherUploads.filter((item) => {
                    // When teacherUpload wasn't moved && has no editParent && can do picture in picture && has no draft
                    // that is based on it then show it
                    return !this.wasMovedByMe(item) && !item.editParent && (!('canPip' in item) || item.canPip)
                        && !this.appointment.drafts.some((draft) => draft.reference === item._id);
                });
            }
        },

        materialTeacherUploadsToDisplayWithUserNote() {
             return this.materialTeacherUploadsToDisplay(false).filter((upload) => {
                 return upload.userNotes.some((el) => el.account === this.person.account);
             })
        },

        //renamed since this was only used for Audio
        //TODO:remove unnecessary code
        async uploadAudioFile(appointmentId, newFileName, fileExtension, file) {
            if (!file) {
                const input = this.$refs.pupilUploadInput;
                file = input.files[0];
            }
            if (file) {
                if (file.size > 1000000000) {
                    this.showSnackbar({ message: "Datei zu groß, max. 1 GB" });
                    return;
                }

                const formData = new FormData();
                formData.append('file', file, `${newFileName}.${fileExtension}`);

                this.pupilXmlHttpRequest = backend.postPupilUpload(appointmentId, file);

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201)
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({ message: "Gespeichert!"});
                    this.pupilXmlHttpRequest = null;
                    this.$refs.pupilUploadInput.value = '';
                    this.requestAppointments();
                });
                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
            }
        },

        openEmptyEdit(newPageType) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'editor',
                params: { mode: 'pupil' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: group + ' ' + new Date().toLocaleDateString(),
                        pagetype: newPageType,
                    }
            });
        },

        clickPupilUploadButton() {
            this.toggleOpenAppointment(this.appointment);
            this.toggleCurrentUploadGroup('pupil');
            this.toggleFachInfoUploadCallback(this.pupilUploadDoneCallback);
            document.getElementById("uploadInput").click();
        },

        pupilUploadDoneCallback() {
            this.toggleFachInfoUploadCallback(null);
            this.requestAppointments();
        },

        //method that sends request to backend
        async uploadViaFileWidget(file) {
            let data = {
                appointmentId: this.appointment._id,
                fileName: file.uploadedName ,
                fileId: file._id,
            };
            let res = await this.postPupilUploadViaFileId(data);
            if (res === true) {
                this.fileWidgetUploadDialog = false;
                this.showSnackbar({ message: "Gespeichert", color: 'success' });
            }

            await this.requestAppointments();
        },

        async clickDownloadPupilUpload(file) {
            try {
                const fileId = file.file._id ? file.file._id : file.file;
                const res = await backend.getPupilUpload(this.appointment._id, fileId);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            } catch (e) {
                console.error(e);
            }
        },

        async clickDownloadPupilCorrection(file) {
            let teacherCorrection = null;

            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                if (this.appointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(this.appointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        teacherCorrection = this.appointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;
                try {
                    const res = await backend.getTeacherUpload(this.appointment._id, teacherCorrection.file);
                    let blob = await res.blob();
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = teacherCorrection.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                } catch (e) {
                    console.error(e);
                }
            }
        },

        handleOpenAnsichtClick(file, isMoved) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            // If the exercise was moved by the pupil without an answer and the teacher still want to see which file
            // it was, it should open the teacherUpload file that wasn't answered by the pupil when clicking the button
            if(isMoved && isMoved.length) {
                this.openAnsichtClick(file.title, typeof file.file === 'object' ? file.file._id : file.file, file._id, 'teacher', true)
                return;
            }
            const correction = this.isCorrectionAvailable(file)
            if (correction) {
                    this.openAnsichtClick(correction.title, correction.file, correction._id, 'teacher');
            } else {
                this.openAnsichtClick(file.title, typeof file.file === 'object' ? file.file._id : file.file, file._id, 'pupil')
            }
        },

        openAnsichtClick(name, id, uploadId, uploader) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            this.$router.push({
                name: 'editor',
                params: {mode: 'viewer'},
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: name,
                        fId: id,
                        uploadid: uploadId,
                        ug: uploader
                    }
            });
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                if (this.appointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.appointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.appointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async playAudioPupil(file) {
            try {
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                file.seenByMe = true;
                const res = await backend.getPupilUpload(
                    this.appointment._id,
                    file.file._id ? file.file._id : file.file,
                );
                let blob = await res.blob();
                this.audioUrl = window.URL.createObjectURL(blob);
                this.$emit('audioOverlay', this.audioUrl);
            }catch (e) {
                console.error(e);
            }
        },

        async playPupil(file) {
            try {
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                file.seenByMe = true;
              const res = await backend.getPupilUpload(this.appointment._id,file.file);
                let blob = await res.blob();
                this.videoUrl = window.URL.createObjectURL(blob);
            }catch (e) {
                console.error(e);
            }
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                if (this.appointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.appointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.appointment.teacherUploads[i];
                    }
                }
            }
            return false;
        },

        async pupilUploadInputChange(appointmentId) {
            const input = this.$refs.pupilUploadInput;
            const file = input.files[0];
            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf('.');
                const fileName = name.substring(0, lastDot);
                const fileExtension = name.substring(lastDot + 1);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        whitenIconBasedOnBackground(backgroundColor) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor);
            const rgb = [];
            rgb[0] = parseInt(result[1], 16);
            rgb[1] = parseInt(result[2], 16);
            rgb[2] = parseInt(result[3], 16);
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000);
            // return (brightness < 160);
            return true;
        },

        async clickDownloadTeacherUpload(file) {
            try {
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                file.seenByMe = true;
                const res = await backend.getTeacherUpload(this.appointment._id, typeof file.file === 'object' ? file.file._id : file.file);
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function(){
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            }catch (e) {
                console.error(e);
            }
        },

        pupilUploadFileAsAnswer(file) {
            try {
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                // To make sure seen by me is also set in backend
                backend.getTeacherUpload(this.appointment._id, typeof file.file === 'object' ? file.file._id : file.file);

                // Normal upload stuff
                this.toggleOpenAppointment(this.appointment);
                this.toggleCurrentUploadGroup('pupil');
                this.toggleOpenTeacherUploadId(typeof file.file === 'object' ? file.file._id : file.file);
                this.toggleFachInfoUploadCallback(this.pupilUploadDoneCallback);
                document.getElementById("uploadInput").click();
            } catch (e) {
                console.warn(e);
            }
        },

        fileNotSupported() {
            this.showSnackbar({ message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter.", color: "error"})
        },

        async openEdit(name, id, uploadId, uploader, upload) {
            const isFileDeleted = this.checkForDeletion(upload);
            if (isFileDeleted) return;
            if(typeof id === 'object') {
                id = id._id;
            }
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const userGroup = this.accountsById[uploader].role;
            upload.seenByMe = true;
            await this.addOrUpdateAppointment(this.appointment);

            this.$router.push({
                name: 'editor',
                params: { mode: 'pupil' },
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: name,
                        fId: id,
                        uploadid: uploadId,
                        ug: userGroup
                    }
            });
        },

        openAlreadyEditedDialog(upload) {
            const isFileDeleted = this.checkForDeletion(upload);
            if (isFileDeleted) return;
            this.alreadyEditedUpload = upload;
            this.alreadyEditedDialog = true;
        },

        alreadyEditedByMe(upload) {
            if(upload.editLeafs.length > 0 && this.appointment.pupilUpload.length > 0) {
                const editors = upload.editLeafs.map((leaf) => {
                    const pupilUpload = this.appointment.pupilUpload.find(pupilUpload => pupilUpload._id === leaf);
                    if(pupilUpload && pupilUpload.uploader) {
                        return pupilUpload.uploader;
                    }
                })
                return editors.some(account => account === this.person.account);
            }
            return false;
        },

        async playAudio(file) {
            try{
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                file.seenByMe = true;
                const res = await backend.getTeacherUpload(
                    this.appointment._id,
                    file.file._id ? file.file._id : file.file,
                );
                let blob = await res.blob();
                this.audioUrl = window.URL.createObjectURL(blob);
                this.$emit('audioOverlay', this.audioUrl);
            }catch (e) {
                console.error(e);
            }
        },

        async play(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            file.seenByMe = true;
            let pipFile = null;
            if (file.pipReference)
                pipFile = this.appointment.teacherUploads.find(value => value._id === file.pipReference);

            if(file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(this.appointment._id, file.file);
                const secondary = await backend.getTeacherUpload(this.appointment._id, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(this.appointment._id, file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
        },

        wasMovedByMe(teacherUpload) {
            if(teacherUpload.isMoved) {
                for (let i = 0; i < teacherUpload.isMoved.length; i++) {
                    if (teacherUpload.isMoved[i] === this.person.account) {
                        return true;
                    }
                }
            }
            return false;
        },


        async greet(event) {
            if ('speechSynthesis' in window) {
                // Speech Synthesis supported 🎉
            } else {
                // Speech Synthesis Not Supported 😣
                alert("Sorry, your browser doesn't support text to speech!");
                return
            }

            let text = this.appointment.note;
            if (this.appointment.note && this.appointment.note !== '') {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = this.appointment.note;
                text = 'Info: ' + tmp.textContent || tmp.innerText || "";
            } else {
                text = 'Info: Es liegt keine Fachinfo für diese Stunde vor.';
            }

            if (window.speechSynthesis.speaking) {
                window.speechSynthesis.cancel();
                event.target.src = lautsprecherIcon;
            } else {
                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang,
                        textToTranslate: text
                    });

                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)
                }

                event.target.src = lautsprecherAusIcon;
                let msg = new SpeechSynthesisUtterance();
                msg.text = text;
                if (this.isLangPackageAvailable()) {
                    msg.lang = this.targetLang;
                    window.speechSynthesis.speak(msg);
                } else {
                    event.target.src = lautsprecherIcon;
                }

                msg.onend = function () {
                    event.target.src = lautsprecherIcon;
                }
            }
        },
        showSpeakerBtn(){
            const element = this.$refs[`speaker`];
            if (element) {
                    element.classList.remove('showSpeaker');

                    setTimeout(() => {
                        element.classList.add('showSpeaker');
                    }, 5000);
                }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        /**
         * #region document draft methods
         */
        async openDraftForEdit({ draft, reference }, mode = 'creator') {
            try {
                const isFileDeleted = this.checkForDeletion(draft);
                if (isFileDeleted) return;
                const group = this.appointment.schoolSubject.name;
                const color = this.appointment.schoolSubject.color;
                const appointmentId = this.appointment._id;

                if (!draft.uploadMessage) {
                    await this.requestAppointments();
                }

                const query = {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    fId: draft.file || draft.uploadMessage.file,
                    title: draft.name,
                    uploadid: reference,
                    draftId: draft._id,
                    ug: 'draft'
                };

                this.$router.push({
                    name: 'editor',
                    params: { mode },
                    query,
                });
            } catch (e) {
                console.error(e);
                this.showSnackbar({ message: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche die Seite neu zu laden.", color: "error" })
            }
        },

        async deleteDraft(draftId) {
            await this.deleteDocumentDraft(draftId);
            for (let index = 0; index < this.appointment.drafts.length; index++) {
                if (this.appointment.drafts[index].draft._id === draftId) {
                    this.appointment.drafts.splice(index, 1);
                }
            }
        },

        uploadIcon(name) {
            var icon;
            if (['png', 'jpg', 'jpeg', 'bmp', 'gif'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.imageIcon;
            }
            if (['doc', 'docx'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.wordIcon;
            }
            if (['ppt', 'pptx'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.powerpointIcon;
            }
            if (['xls', 'xlsx'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.excelIcon;
            }
            if (['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.musicIcon;
            }
            if (['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.videoIcon;
            }
            if (['pdf'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.pdfIcon;
            }
            if (['txt'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.documentIcon;
            }
            return icon;
        },

        checkForDeletion(file) {
            if (file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return true;
            }
            return false;
        },
    }
}
</script>

<style lang="scss" scoped>
    .appointmentNoteText {
        display: flex;
        flex-direction: column;
        font-size: medium;
        background-color: rgba(112,112,112, 0.15);
        border-radius: 8px;
    }

    // i dont know how this works but ios devs recommend this to create responsive fit for embed pdfs
    .iOS_ResponsiveHack {
        width: 1px;
        min-width: 100%;
        *width: 100%;
        padding-left: 10%;
        padding-right: 10%;
    }
    .whiteBackgroundTile {
        background-color: white;
        border-radius: 8px;
        padding: 2px 8px 8px 8px;
    }

    .iconSelect {
        display: inline-flex;
        width: 59px;
        height: 59px;
        padding: 4px;
        border-radius: 4px 4px 4px 0;
        background-color: rgba(234, 234, 234, 0.7);
    }

    .btnStyle {
        min-width: 36px;
        min-height: 36px;
        margin-top: -3px;
    }

    .fileName {
        max-width: 70%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .eHr {
        width: 90%;
        margin: auto;
        border-width: 0;
        height: 2px;
        color: var(--v-hellgrau-base);
        background-color: var(--v-hellgrau-base);
    }

    .iconSize {
        height: 20px;
    }

    .scroll-area {
        position: relative;
        margin: auto;
        max-height: 200px;
    }

    .scroll-area-fachPopup {
        position: relative;
        margin: auto;
        height:calc(70vh - 130px);
        overflow-x: hidden;
    }

    //border-radius of v-menu
    .v-menu__content {
        border-radius: 12px;
    }

    #createEditorABsButton {
        color: white;
        background-color: var(--v-dunkelgrau-base);
    }

    .brighterFileRow {
        background-color: rgba(224, 224, 224, 0.15) !important;
    }

    .correctionAvailableClass {
        background-color: rgba(140, 190, 70, 0.30) !important;
    }

    @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
        .scroll-area-fachPopup {
            /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
            max-height: calc(100vh - 200px);
        }
    }

    .small-drop-down-menu {
        display: block;
        background-color: #F8F8F8;

        & button {
            width: 100%;
        }
    }

    // Overwrite default scrollbar on chrome (only browser that supports smth like this) to look like the vue-custom-scrollbar
    /* width */
    ::-webkit-scrollbar {
        width: 11px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #eee;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 6px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #999;
        border-radius: 6px;
    }
    .showSpeaker{
        display: none;
    }
</style>

