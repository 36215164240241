<template>
    <div>
        <v-overlay
            v-if="chatOpen"
            opacity="0.80"
            z-index="10"
            @click.native="toggleChatOpen"
        />
        <div id="container">
            <v-badge
                color="#ff0000"
                :content="chatBadgeEventList.length"
                :value="chatBadgeEventList.length > 10 ? '10+': chatBadgeEventList.length"
                overlap
                left
                offset-x="30"
                offset-y="15"
                :style="chatOpen ? 'z-index: 11' : 'z-index: 9'"
                style="width: 100%"
            >
                <v-tooltip
                    :value="shortcutTooltips"
                    :disabled="!showTooltips"
                    bottom
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            id="ChatWidgetButton"
                            style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                            x-small
                            elevation="0"
                            class="ml-4"
                            :class="{chatClicked: chatOpen }"
                            v-on="on"
                            @click="toggleChatOpen"
                        >
                            <img
                                :src="chatIconGruen"
                                style="max-width: 25px; max-height: 25px;"
                                :class="{iconToWhite: chatOpen}"
                                :alt="chatOpen ? 'Chat schließen' : 'Chat öffnen'"
                            >
                        </v-btn>
                    </template>
                    <div style="display: flex; flex-direction: column; text-align: center">
                        <span><span style="text-decoration: underline white">C</span>hat (Alt + C)</span>
                        <img
                            v-if="hoeren"
                            :src="chatMetacom"
                            width="100"
                            style="margin: auto"
                        >
                    </div>
                </v-tooltip>
                <ChatWidget
                    v-show="chatOpen"
                    id="ChatWidget"
                    usage="BabyChat"
                    @updateMessageCount="updateMessageCount"
                />
            </v-badge>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import chatIconGruen from '../../assets/Icons/chat-alternative-gruen-102.svg';
import chatMetacom from '../../assets/METACOM/Chat/chatchatten.png'

import ChatWidget from "@/components/ChatV2/ChatWidgetV2";

export default {
name: "BabyChatDropDown",
    components: {
        ChatWidget,
    },
    props: {
        popupChat: {required: false, default: false},
    },
    data() {
        return {
            chatOpen: false,
            sehen: false,
            hoeren: false,
            babyView: false,
            chatIconGruen,
            chatMetacom,
            newMessagesAvailable: false,
            newMessagesCount: 0,
        };
    },
    computed : {
        ...mapState("speechControl", ["word"]),
        ...mapState("tooltips", ["shortcutTooltips", "showTooltips"]),
        ...mapGetters("gamepad", { gamepadYButtonPressed: "yButtonPressed"}),
        ...mapGetters("chat", ["chatRoomEventByFile","chatBadgeEventList"]),

    },
    watch: {
        word(newVal) {
            if(newVal === 'öffne chat') {
                this.chatOpen = true;
            }
            if(newVal === 'schließe chat') {
                this.chatOpen = false;
            }
        },
        popupChat(newVal) {
            if(newVal) {
                this.chatOpen = newVal;
            }
            // setTimeout(() => {
            //     this.$emit('closeOtherPopups', true);
            // }, 10);
        },
        gamepadYButtonPressed(newVal, oldVal) {
            // on keyup of gamepadYButton
            if(oldVal === true && newVal === false) {
                this.toggleChatOpen();
            }
        }
    },
    mounted() {
        this.requestBabyViewAndAccessibilityMode();
        window.addEventListener("keydown", this.handleKeyUp);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyUp);
    },
    methods: {
        ...mapActions("tooltips", ["triggerShortcutTooltips"]),
        ...mapActions("pupils", ["getMePupil"]),
        handleKeyUp(event) {
            if (event.altKey && event.key === 'c') {
                this.toggleChatOpen();
                this.triggerShortcutTooltips();
            }
        },
        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
            } else {
                this.sehen = false;
                this.hoeren = false;
            }
            //to check if they are babys
            if (this.babyView) {
                this.babyViewTut = true;
            }
        },
        toggleChatOpen() {
            if(!this.chatOpen) {
                document.getElementById("ChatWidgetButton").focus();
            }
            this.chatOpen = !this.chatOpen;
            if (this.chatOpen) {
                this.$emit('openPopup');
            }
        },
        updateMessageCount(count) {
            this.newMessagesCount = count;
            this.newMessagesAvailable = count > 0;
        },
    },
}
</script>

<style lang="scss" scoped>
#container {
    position: relative;
}

#ChatWidget {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    width: 400px;
    max-width: 95vw;
}

.animateIcon {
    overflow: hidden;
}

.animateIcon:after {
    animation: shine 60s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.0);
    background: linear-gradient(
            to right,
            rgba(112, 112, 112, 0.2) 0%,
            rgba(112, 112, 112, 0.2) 77%,
            rgba(112, 112, 112, 0.5) 92%,
            rgba(112, 112, 112, 0.0) 100%
    );
}

/* Hover state - trigger effect */

/* Active state */
.animateIcon:active:after {
    opacity: 0;
}

@keyframes shine{
    1% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    5% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.chatClicked {
    background-color: var(--v-chatColor-base) !important;
}
</style>
