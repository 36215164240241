import { checkResponseStatus } from '@/util/check';
import * as backend from '../../api/backend';

const defaultState = {
    teachers: [],
    meTeacher: null,
    teachersInvalid: false,
    profilePictures: [],
    allTeacherGroups: [],
};

const mutations = {
    setTeachers: (state, list) => {
        state.teachers = list;
    },
    setTeacherGroups: (state, list) => {
        state.allTeacherGroups = list;
    },
    setTeachersInvalid: (state, status) => {
        state.teachersInvalid = status;
    },
    setMeTeacher: (state, me) => {
        state.meTeacher = me;
    },
    clearCurrentTeacher: (state) => {
        state.meTeacher = null;
    },
    pushNewTeacher: (state, teacher) => {
        if (state.teachers.findIndex(el => el._id === teacher._id) < 0) {
            state.teachers.push(teacher);
        }
    },
    pushProfilePictureToState: (state, { teacherId, url } ) => {
        state.profilePictures.push({ _id: teacherId, url });
    },
    removeProfilePictureFromState: (state, teacherId) => {
        state.profilePictures.forEach((picture) => {
            if (teacherId === picture._id) {
                window.URL.revokeObjectURL(picture.url);
            }
        })
        state.profilePictures = state.profilePictures.filter((picture) => {
            return picture._id !== teacherId;
        })
    }
};

const getters = {
    getTeacherStatus: state => state.teachersInvalid,
    teachers: state => state.teachers,
    teachersById: state => state.teachers.reduce((acc, teacher) => {
        acc[teacher._id] = teacher;
        return acc;
    }, {}),
    teachersByAccountId: state => state.teachers.reduce((acc, teacher) => {
        acc[teacher.account] = teacher;
        return acc;
    }, {}),
    simpleEditorForTeacher: state => state.meTeacher ? false : undefined,
    meTeacher: state => state.meTeacher,
    profilePictures: state => state.profilePictures,
    profilePicturesById: state => state.profilePictures.reduce((acc, picture) => {
        acc[picture._id] = picture.url;
        return acc;
    }, {}),
    allTeacherGroups: state => state.allTeacherGroups,
}

const actions = {
    setTeachersInvalidStatus({ commit }, status) {
        commit('setTeachersInvalid', status);
    },

    async createTeacher({ commit, dispatch }, params) {
        try {
            const res = await backend.postTeacher(params);
            await checkResponseStatus(201, res);
            const teacher = await res.json();
            commit('pushNewTeacher', teacher);
            
            return teacher;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async uploadTeacherPic({ commit, dispatch }, params) {
        try {
            const res = await backend.postTeacherPicture(params);
            await checkResponseStatus(201, res);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editTeacher({ commit, dispatch }, params) {
        try {
            const id = params._id
            delete params._id;
            const res = await backend.patchTeacher(id, params);
            if (res.status === 409) {
                return 409;
            }else{
                const teacher = await res.json();
                commit('setMeTeacher', teacher);
                return teacher;
            }
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async editTeacherProfile({ commit }, params) {
        try {
            let id = params._id;
            delete params._id;
            return await backend.patchTeacherProfile(id, params);
        }
        catch(err) {
            return err;
        }
    },

    async deleteTeacher({ commit, dispatch, getters }, id) {
        try {
            const res = await backend.deleteTeacher(id);
            if(res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            const teachers = getters.teachers;
            const index = teachers.findIndex((teacher) => teacher._id === id);
            delete teachers[index];
            commit('setTeachers', teachers);
            return true;
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },

    async deleteUpload({ commit, dispatch }, id) {
        try {
            const res = await backend.deleteUpload(id);
            if(res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            return true;
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },

    async getTeachers({ commit, dispatch, getters }, update = false) {
        try {
            if (getters.teachers && getters.teachers.length > 0 && !update) {
                return getters.teachers;
            }

            const res = await backend.getTeachers();
            await checkResponseStatus(200, res);
            const tempArray = await res.json();
            const teachers = tempArray.filter(el => el.lastName !== 'Nachname');
            teachers.sort((a, b) => a.name.localeCompare(b.name));
            commit('setTeachers', teachers);
            return teachers;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getMeTeacher({ commit, getters }) {
        try {
            if (getters.meTeacher) {                
                return getters.meTeacher;
            }
            const res = await backend.getMeTeacher();
            await checkResponseStatus(200, res);
            const result = await res.json();
            commit('setMeTeacher', result);
            return result;
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getMyTeacherGroups({commit, getters, state},update = false){
        if(update || state.allTeacherGroups.length === 0){
            const teacherGroupRes = await backend.getAllTeacherGroups(getters.meTeacher._id);
            const teacherGroups = await teacherGroupRes.json();
            commit('setTeacherGroups', teacherGroups);
            return teacherGroups
        }
      return state.allTeacherGroups;
    },
    async getProfilePicture({commit, dispatch, getters}, teacherId) {
        try {
            if (getters.profilePicturesById[teacherId]) {
                return getters.profilePicturesById[teacherId];
            }
            const res = await backend.getProfilePicture(teacherId);
            await checkResponseStatus(200, res);
            const blob = await res.blob();
            if (blob) {
                const url = URL.createObjectURL(blob);
                commit('pushProfilePictureToState', {teacherId, url});
                return url;
            }
            return null;
        } catch (err) {
            console.error(err);
            return null;
            // return err?.response?.status;
        }
    },

    async deleteProfilePicture({ commit, dispatch}, teacherId) {
        try {
            const res = await backend.deleteProfilePicture(teacherId);
            await checkResponseStatus(204, res);
            return res.status;
        }
        catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    /**
     * This method gives you all class teachers of one desired pupil.
     *
     * @param pupilAccountId pupil's account ID
     * @returns {Promise<any>} array of this pupil's class teachers
     */
    async getClassTeachers({ commit }, pupilAccountId) {
        try {
            const res = await backend.getClassTeachers(pupilAccountId);
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch(err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getAllGroupTeachers({commit}, accountId){
       try{
         const res = await backend.getPupilsTeachers(accountId);
             await checkResponseStatus(200, res)
            return await res.json();
       } catch(err) {
        console.error(err);
        return err.response.status;
       }
    }

};

export default {
    namespaced: true,
    state: defaultState,
    getters,
    mutations,
    actions,
};
