import * as backend from '../../api/backend';
import {checkResponseStatus} from '@/util/check';
import Vue from "vue";

const enhanceAppointment = (appointment, stateAppointments) => {
    appointment.group = appointment.group._id || appointment.group;
    appointment.teacherUploads.forEach(upload => {
        if (upload.seenByMe) {
            return;
        }
        const foundStateAppointment = stateAppointments.find(el => el._id === appointment._id);
        if (!foundStateAppointment) {
            return;
        }
        const foundTeacherUpload = foundStateAppointment.teacherUploads.find(el => el._id === upload._id);
        upload.seenByMe = foundTeacherUpload && foundTeacherUpload.seenByMe;
    });
    if (appointment.timeslot.start && typeof appointment.timeslot.start !== 'object'
        && appointment.timeslot.end && typeof appointment.timeslot.end !== 'object') {
        appointment.timeslot.start = new Date(appointment.timeslot.start);
        appointment.timeslot.end = new Date(appointment.timeslot.end);
    }
    // Todo add weekId for easier compute in getters
    appointment.weekId = `${new Date(appointment.day).getWeek()}-${new Date(appointment.day).getFullYear()}`
    return appointment;
};

const checkAppointmentDiff = (appointmentsByWeek, date, addWeek = false, subWeek = false) => {
    const weekId = `${date.getWeek()}-${date.getFullYear()}`;

    if(addWeek){
        const previousWeek =`${date.getWeek() - 1}-${date.getFullYear()}`;
       return Math.abs((appointmentsByWeek[previousWeek] || []).length 
            - (appointmentsByWeek[weekId] || []).length ) < 3
    }
    else {
        return true;
   }
}

const state = {
    appointments: [],
    appointmentsByRooms: [],
    appointmentsByRoomsBuffer: [],
    appointmentBuffer: [],
    //Key: UploadId; Value: thumbnail blob url
    thumbnails: new Map(),
    planBadgeEventList: [],
    appointmentsLoading: false,
    appointmentsByTeachers: [],
    appointmentsByTeachersBuffer: [],
};

const getters = {
    appointments: state => state.appointments,
    appointment: state => appId => state.appointments.find(appointment => appointment._id === appId),
    appointmentsById: state => state.appointments.reduce((id, appointment) =>{
        id[appointment._id] = appointment;
        return id;
    },{}),
    uncorrectedPupilUploadsInAppointment: state => (appId, currentUpload) => {
        const appointment = state.appointments.find(appointment => appointment._id === appId);
        if (appointment) {
            return appointment.pupilUpload.find(upload =>
                upload._id !== currentUpload
                && !upload.editLeafs.length
                && ['pdf', 'png', 'jpg', 'jpeg', 'tiff', 'bmp'].includes(upload.title.split('.').pop().toLowerCase())
            );
        }
        return null;
    },
    getThumbnailFromUpload: state => (uploadId) => {
      const foundThumbnail = state.thumbnails.get(uploadId);
      return foundThumbnail;
    },
    weekId: state => {
        const date = new Date();
        return `${date.getWeek()}-${date.getFullYear()}`;
    },
    appointmentsByWeek: state => state.appointments.reduce((week, appointment) => {
        // Todo remove this later, getter should not change state vars
        if (!appointment.weekId) {
            appointment.weekId = `${new Date(appointment.day).getWeek()}-${new Date(appointment.day).getFullYear()}`;
        }
        const weekId = appointment.weekId;
        if (!week[weekId]) {
            week[weekId] = [];
        }
        week[weekId].push(appointment);
        return week;
    }, {}),
    appointmentsByRooms:  (state) => {
        return state.appointmentsByRooms.reduce((appointmentsByRoomId, appointment) => {
          appointment.rooms.forEach((room) => {
            const room_id = room._id;
    
            if (!appointmentsByRoomId[room_id]) {
              appointmentsByRoomId[room_id] = [];
            }
    
            appointmentsByRoomId[room_id].push(appointment);
          });
    
          return appointmentsByRoomId;
        }, {})},
        appointmentsByTeacherId:  (state) => {
            return state.appointmentsByTeachers.reduce((appointmentsByTeacherId, appointment) => {
              appointment.teachers.forEach((teacher) => {
                const teacher_id = teacher._id;
        
                if (!appointmentsByTeacherId[teacher_id]) {
                  appointmentsByTeacherId[teacher_id] = [];
                }
        
                appointmentsByTeacherId[teacher_id].push(appointment);
              });
        
              return appointmentsByTeacherId;
            }, {})},
    planBadgeEventList: state => state.planBadgeEventList,
    appointmentsLoading: state => state.appointmentsLoading,
};

const mutations = {
    handleAppointmentsByWeek: (state, appointments) => {

        // enhance appointments before state update
        const enhanced = appointments
            .map(appointment => enhanceAppointment(appointment, state.appointments));

        if (!state.appointments) {
            state.appointments = enhanced;
            return;
        }
        // are there appointments that are already in state.appointments?
        const filteredBuffer = state.appointmentBuffer.filter(bufferItem => {
            return !state.appointments.some((appointment) => appointment._id === bufferItem._id);
        });

         // is buffer non-empty?
        if (filteredBuffer.length) {
            // if yes then do so
            state.appointments = state.appointments.concat(filteredBuffer);
            // empty buffer
            state.appointmentBuffer = [];
        }

        // push new appointments into buffer
        state.appointmentBuffer = enhanced;
    },
    handleAppointmentsByRoomAndWeek: (state, appointments) => {

        // enhance appointments before state update
        const enhanced = appointments
            .map(appointment => enhanceAppointment(appointment, state.appointmentsByRooms));

        if (!state.appointmentsByRooms) {
            state.appointmentsByRooms = enhanced;
            return;
        }
        // are there appointments that are already in state.appointments?
        const filteredBuffer = state.appointmentsByRoomsBuffer.filter(bufferItem => {
            return !state.appointmentsByRooms.some((appointment) => appointment._id === bufferItem._id);
        });

         // is buffer non-empty?
        if (filteredBuffer.length) {
            // if yes then do so
            state.appointmentsByRooms = state.appointmentsByRooms.concat(filteredBuffer);
            // empty buffer
            state.appointmentsByRoomsBuffer = [];
        }

        // push new appointments into buffer
        state.appointmentsByRoomsBuffer = enhanced;
    },
    handleAppointmentsByTeacherAndWeek: (state, appointments) => {

        // enhance appointments before state update
        const enhanced = appointments
            .map(appointment => enhanceAppointment(appointment, state.appointmentsByTeachers));

        if (!state.appointmentsByTeachers.length > 0 ) {
            state.appointmentsByTeachers = enhanced;
            return;
        }
        // are there appointments that are already in state.appointments?
        const filteredBuffer = state.appointmentsByTeachersBuffer.filter(bufferItem => {
            return !state.appointmentsByTeachers.some((appointment) => appointment._id === bufferItem._id);
        });

         // is buffer non-empty?
        if (filteredBuffer.length) {
            // if yes then do so
            state.appointmentsByTeachers = state.appointmentsByTeachers.concat(filteredBuffer);
            // empty buffer
            state.appointmentsByTeachersBuffer = [];
        }

        // push new appointments into buffer
        state.appointmentsByTeachersBuffer = enhanced;
    },
    emptyTeachersBufferIntoState: (state) => {
        const filteredBuffer = state.appointmentsByTeachersBuffer.filter(bufferItem => {
            return !state.appointmentsByTeachers.some((appointment) => appointment._id === bufferItem._id);
        });
        state.appointmentsByTeachers = state.appointmentsByTeachers.concat(filteredBuffer);
        state.appointmentsByTeachersBuffer = [];
    },
    emptyRoomsBufferIntoState: (state) => {
        const filteredBuffer = state.appointmentsByRoomsBuffer.filter(bufferItem => {
            return !state.appointmentsByRooms.some((appointment) => appointment._id === bufferItem._id);
        });
        state.appointmentsByRooms = state.appointmentsByRooms.concat(filteredBuffer);
        state.appointmentsByRoomsBuffer = [];
    },
    emptyBufferIntoState: (state) => {
        const filteredBuffer = state.appointmentBuffer.filter(bufferItem => {
            return !state.appointments.some((appointment) => appointment._id === bufferItem._id);
        });
        state.appointments = state.appointments.concat(filteredBuffer);
        state.appointmentBuffer = [];
    },
    setAppointmentsByRoomsBuffer: (state, bufferAppointments) => {
        Vue.set(state, 'appointmentsByRoomsBuffer', bufferAppointments);
    },
    setAppointmentsByTeachersBuffer: (state, bufferAppointments) => {
        Vue.set(state, 'appointmentsByTeachersBuffer', bufferAppointments);
    },
    setAppointmentBuffer: (state, bufferAppointments) => {
        Vue.set(state, 'appointmentBuffer', bufferAppointments);
        // state.appointmentBuffer = bufferAppointments;
    },
    setAppointments: (state, appointments) => {
        Vue.set(state, 'appointments', appointments);
        // state.appointments = appointments;
    },
    addOrUpdateAppointment: (state, appointment) => {
        //stuff for roomMode state
        const enhancedForRoomState = enhanceAppointment(appointment,state.appointmentsByRooms);
        const roomStateIndex = state.appointmentsByRooms.findIndex(el => {
            return el._id === enhancedForRoomState._id;
        })
        if (roomStateIndex >= 0){
            Vue.set(state.appointmentsByRooms, roomStateIndex, enhancedForRoomState);

        }else{
            state.appointmentsByRooms.push(enhancedForRoomState);
        }

        appointment.group = appointment.group._id ? appointment.group._id : appointment.group; // Need to save ID only because object gets deleted when creating deep copy in getAppointments()
        appointment.teacherUploads.forEach((teacherUpload1) => {
            if (teacherUpload1.seenByMe) {
                return;
            }
            const foundStateAppointment = state.appointments.find((appointment1) => appointment1._id === appointment._id);
            if (!foundStateAppointment) {
                return;
            }
            const foundTeacherUpload = foundStateAppointment.teacherUploads.find((teacherUpload2) => teacherUpload2._id === teacherUpload1._id);
            if (foundTeacherUpload && foundTeacherUpload.seenByMe) {
                teacherUpload1.seenByMe = true;
            }
        });
        const index = state.appointments.findIndex(el => {
            return  el._id === appointment._id;
        });

        if (index >= 0){
            Vue.set(state.appointments, index, appointment);

        }else{
            state.appointments.push(appointment);
        }

    },
    addOrUpdateAppointments: (state, appointments, isTeacher) => {
        const newAppointments = state.appointments;
        appointments.map(appointment => {
            // refactor appointment data for frontend
            appointment = enhanceAppointment(appointment, state.appointments);

            // set new appointment in list
            const index = newAppointments.findIndex(el => el._id === appointment._id);
            if (index >= 0) {
                Vue.set(state.appointments, index, appointment);
            } else {
                state.appointments.push(appointment);
            }
            //if roomstate exists do this there aswell
            if(state.appointmentsByRooms.length > 0){
                const appByRoom = enhanceAppointment(appointment, state.appointmentsByRooms);
                const indexRoom = state.appointmentsByRoom.findIndex(el => el._id === appointment._id);
            if (indexRoom >= 0) {
                Vue.set(state.appointmentsByRoom, indexRoom, appByRoom);
            } else {
                state.appointmentsByRoom.push(appByRoom);
            }
            }
        });
        // set appointments list
        // state.appointments = newAppointments;
        // if(state.appointmentsByRooms.length > 0){
        //     const newAppointmentsByRooms = state.appointmentsByRooms;
        //         newAppointmentsByRooms.forEach(appointment => {
        //             // refactor appointment data for frontend
        //         appointment = enhanceAppointment(appointment, state.appointmentsByRooms);

        //         // set new appointment in list
        //         const roomStateIndex = newAppointments.findIndex(el => el._id === appointment._id);
        //             if (roomStateIndex >= 0) {
        //                 newAppointmentsByRooms.splice(roomStateIndex, 1, appointment);
        //             } else {
        //                 newAppointmentsByRooms.push(appointment);
        //             }
        //         })
        //     state.appointmentsByRooms = newAppointmentsByRooms;
        // }
    },
    removeAppointment: (state, appointmentId) => {
        const index = state.appointments.findIndex(el => el._id === appointmentId);
        if (index >= 0) {
            state.appointments.splice(index, 1);
            Vue.set(state, 'appointments', state.appointments);
        }
        const roomStateIndex = state.appointmentsByRooms.findIndex(el => el._id === appointmentId);
        if (roomStateIndex >= 0) {
            state.appointmentsByRooms.splice(roomStateIndex, 1);
            Vue.set(state, 'appointmentsByRooms', state.appointmentsByRooms);
        }
    },
    patchAppointment: (state, newAppointment) => {
        const index = state.appointments.findIndex(el => el._id === newAppointment._id);
        if (index >= 0) {
            Vue.set(state.appointments, index, newAppointment);
        } else {
            state.appointments.push(newAppointment);
        }
    },
    clearAppointments: (state) => {
        state.appointments = [];
    },
    addThumbnail: (state, { uploadId, blob }) => {
        state.thumbnails.set(uploadId, blob);
    },
    setPlanBadgeEventList: (state, planBadgeEventList) => {
        state.planBadgeEventList = planBadgeEventList;
    },
    setAppointmentsLoading: (state, value) =>{
        state.appointmentsLoading =value;
    },
    removePlanBadgeEventByAppointment: (state, appointmentId) => {
        const index = state.planBadgeEventList.findIndex(el => el.appointment === appointmentId);
        state.planBadgeEventList.splice(index, 1);
    },
    addOrUpdatePlanBadge: (state, {appointmentId, createdUpload}) => {
        console.log('addOrUpdatePlanBadge', appointmentId, createdUpload);
        const fullAppointment = state.appointments.filter((appointment)=> appointment._id === appointmentId);
        const appointmentDate = fullAppointment.length ? fullAppointment[0].day : null; 
        const event = {
            appointmentId,
            date: appointmentDate,
            note: '',
            uploads: createdUpload,
        }
        const index = state.planBadgeEventList.findIndex(el => el.appointment === appointmentId);
        if (index >= 0) {
            Vue.set(state.planBadgeEventList, index, event);
        } else {
            state.planBadgeEventList.push(event);
        }
     
    },
    removePlanBadgeEventByUpload: (state, {uploadId, role}) => {
        if (role === 'pupil') {
            let appointment = state.appointments.find(el => el.teacherUploads.find(upload => upload._id === uploadId));
            if(appointment){
                const uploadIndex = appointment.teacherUploads.findIndex(upload => upload._id === uploadId);
                appointment.teacherUploads[uploadIndex].seenByMe = true;
            }
        } else {
            let appointment = state.appointments.find(el => el.pupilUpload.find(upload => upload._id === uploadId));
            if(appointment){
            const uploadIndex = appointment.pupilUpload.findIndex(upload => upload._id === uploadId);
            appointment.pupilUpload[uploadIndex].seenByMe = true;
            }
        }
        const index = state.planBadgeEventList.findIndex(el => el.appointment === uploadId);
        state.planBadgeEventList.splice(index, 1);
    },
};

const actions = {
    async createAppointment({commit, dispatch, rootGetters}, params) {
        try {
            const res = await backend.postSchoolSubjectAppointment(params);
            await checkResponseStatus(201, res);
            const appointment = await res.json();
            let updatedAppointment = [];
            // we need to distinguish between if return value is one instance or an array,
            // because if appointment is created weekly, an array is beeing returned.
            if (appointment) {
                if (Array.isArray(appointment)) {
                    updatedAppointment = await Promise.all(appointment.map(async (appointment) => {
                        await dispatch('updateDisplayTeacherInfo',appointment)
                        return appointment;
                      }));
                    commit('addOrUpdateAppointments', updatedAppointment, rootGetters['auth/accountRole'] === 'teacher');
                } else {
                    updatedAppointment =  await dispatch('updateDisplayTeacherInfo',appointment)
                    commit('addOrUpdateAppointment', updatedAppointment);
                }
            }
            return updatedAppointment;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async deleteAppointment({commit, dispatch}, params) {
        let res;
        let id;
        let ruleId;

        try {
            switch (params.type) {
                case 'onlyThis':
                    id = params.appointment._id;
                    res = await backend.deleteSchoolSubjectAppointment(id);
                    break;
                case 'thisAndFollowing':
                    id = params.appointment._id;
                    ruleId = params.appointment.rule;
                    res = await backend.deleteSchoolSubjectAppointmentFromRule(id, ruleId);
                    break;
                case 'allOfThem':
                    ruleId = params.appointment.rule;
                    res = await backend.deleteSchoolSubjectAppointmentRule(ruleId);
                    break;
            }

            if (res.status === 409) {
                return 409;
            }
            await checkResponseStatus(204, res);
            //todo: fix:logger shows that appointment is beeing removed from state,
            //but after switching date deleted appointment is shown again(although deleted from backend)
            commit('removeAppointment', params.appointment._id);
            // Reload all the appointments, because a lot of them might have changed
            dispatch('getAppointments', true);
            return true;
        } catch (e) {
            console.error(e)
            if (res.status) {
                return res.status;
            }
            return false;
        }
    },

    async getAppointmentRuleById({commit, dispatch}, params) {
        try {
            const res = await backend.getSchoolSubjectAppointmentRuleFromId(params);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    //when updating any appointment this needs to be done before the appointment is commited into the state
    //this maps the teachers that are displayed in the top right corner of the component inside an extra list to
    //reduce computations that would need to be made multiple times in the Fach component.
    async updateDisplayTeacherInfo({ commit }, appointment) {
        if (appointment.substitutions) {
          const excludeTeachersFromList = [];
          const sickTeachers = await Promise.all(appointment.substitutions.map(async (sub) => {
            if(sub.substitutionTeacher){
                excludeTeachersFromList.push(sub.sickTeacher._id);
            }
            const teacher = sub.substitutionTeacher || sub.sickTeacher;
            const role = sub.substitutionTeacher ? 'substitution' : 'sickTeacher';

            return { teacher, role };
          }));

          const nonDuplicateTeachers = appointment.teachers.filter(
            teacher => !sickTeachers.some(sickTeacher => sickTeacher.teacher._id === teacher._id)
                && !excludeTeachersFromList.some(excludeTeacher => excludeTeacher === teacher._id)
          );
          const uniqueTeachers = [...sickTeachers, ...nonDuplicateTeachers.map(teacher => ({ teacher, role: 'normal' }))];
          // Remove duplicates based on teacher._id
          const displayTeacherInfo = Array.from(new Map(uniqueTeachers.map(item => [item.teacher._id, item])).values());
          appointment.displayTeacherInfo = displayTeacherInfo;
        } else {
          appointment.displayTeacherInfo = appointment.teachers.map(teacher => ({ teacher, role: 'normal' }));
        }
        return appointment;
      },

    async getAppointments({commit, dispatch, getters, rootGetters}, fetch = false) {
        try {
            const setTimeslotsInAppointments = async (appointments) => {
                if(rootGetters['auth/accountRole'] === 'teacher') {
                    await Promise.all(appointments.map(async (appointment) => {
                        if ((appointment.timeslot.start && typeof appointment.timeslot.start !== 'object')
                            && (appointment.timeslot.end && typeof appointment.timeslot.end !== 'object')) {
                            appointment.timeslot.start = new Date(appointment.timeslot.start);
                            appointment.timeslot.end = new Date(appointment.timeslot.end);
                        }
                    }));
                }
            }

            if (getters.appointments.length > 0 && !fetch) {
                const appointments = JSON.parse(JSON.stringify(getters.appointments));
                await setTimeslotsInAppointments(appointments);
                return appointments;
            }
            const res = await backend.getSchoolSubjectAppointments();
            await checkResponseStatus(200, res);
            const appointments = await res.json();
            await setTimeslotsInAppointments(appointments);
            // const updatedAppointments = await Promise.all(appointments.map(async (appointment) => {
            //     await dispatch('updateDisplayTeacherInfo',appointment)
            //     return appointment;
            //   }));
            // This needs to be a deep copy because otherwise appointments is protected as vuex state when it was returned
            // commit('setAppointments', JSON.parse(JSON.stringify(updatedAppointments)));
            return appointments;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getPlanBadgeEventList({commit, dispatch, getters, rootGetters}){
        const res = await backend.getPlanBadgeEventList();
        const response = await res.json();
        commit('setPlanBadgeEventList', response);
    },
    async getTeacherAppointments({commit, dispatch, getters, rootGetters}) { 
    try{
        const setTimeslotsInAppointments = async (appointments) => {
            if(rootGetters['auth/accountRole'] === 'teacher') {
                await Promise.all(appointments.map(async (appointment) => {
                    if ((appointment.timeslot.start && typeof appointment.timeslot.start !== 'object')
                        && (appointment.timeslot.end && typeof appointment.timeslot.end !== 'object')) {
                        appointment.timeslot.start = new Date(appointment.timeslot.start);
                        appointment.timeslot.end = new Date(appointment.timeslot.end);
                    }
                }));
            }
        }
        const res= await backend.getTeacherAppointments()
        await checkResponseStatus(200, res);
        const appointments = await res.json();
        await setTimeslotsInAppointments(appointments);
        return appointments;
    } catch (err) {
        console.error(err);
        return err?.response?.status;
    }
    },

    async getAppointmentsByWeek({commit, dispatch, getters, rootGetters}, { date = new Date(), fetch = false, pupilId='', addWeek =false, subWeek = false, }) {
        try {
            // Todo make extra store action for week pageination
            const setTimeslotsInAppointments = async (appointments) => {
                if(rootGetters['auth/accountRole'] === 'teacher') {
                    await Promise.all(appointments.map(async (appointment) => {
                        if ((appointment.timeslot.start && typeof appointment.timeslot.start !== 'object')
                            && (appointment.timeslot.end && typeof appointment.timeslot.end !== 'object')) {
                            appointment.timeslot.start = new Date(appointment.timeslot.start);
                            appointment.timeslot.end = new Date(appointment.timeslot.end);
                        }
                    }));
                }
            }

            // check if date week is already given
            // Todo how to handle weeks where badges have been loaded but not all appointments?
            // maybe ignore this, have to talk to the other about it
            const weekId = `${date.getWeek()}-${date.getFullYear()}`;
            //we check if in next week there are significantly less appointments than in current,
            // if so we fetch new, since usually in a group there should alwys be close to the same amount of appointments
            // this is done so after creating appointments, not only the new appointmnets are shown
            if (((getters.appointmentsByWeek[weekId] || []).length > 0) && checkAppointmentDiff(getters.appointmentsByWeek, date, addWeek, subWeek) && !fetch) {
                //get list of appointments from given week that is already in state to return
                const appointments = JSON.parse(JSON.stringify(getters.appointmentsByWeek[weekId]));
                await setTimeslotsInAppointments(appointments);
                commit('setAppointmentsLoading', false);
            
                return appointments;
            }

            // add first load query param for badge appointments
            const firstLoad = getters.appointments.length === 0;
            commit('setAppointmentsLoading',true);
            const res = await backend.getSchoolSubjectAppointmentsForWeek(weekId, firstLoad, pupilId);
            await checkResponseStatus(200, res);
            const response = await res.json();
            let { weekSchoolSubjectAppointments: appointments } = response;
            await setTimeslotsInAppointments(appointments);
            // This needs to be a deep copy because otherwise appointments is protected as vuex state when it was returned
            // Todo for adding appointments, set update only on week change
            // Todo on first load, load current plus surrounding weeks
            if (firstLoad) {
                commit('setPlanBadgeEventList', response.firstLoadEventList);
                console.log('response.firstLoadEventList',response.firstLoadEventList);
                // const weekBefore = `${date.getWeek()-1}-${date.getFullYear()}`;
                // const weekAfter = `${date.getWeek()+1}-${date.getFullYear()}`;
                // let resBefore;
                // let resAfter;
                // if(pupilId){
                //     resBefore = await backend.getSchoolSubjectAppointmentsForWeek(weekBefore, firstLoad,pupilId);
                //     resAfter = await backend.getSchoolSubjectAppointmentsForWeek(weekAfter, firstLoad, pupilId);
                // }else{
                //     resBefore = await backend.getSchoolSubjectAppointmentsForWeek(weekBefore, firstLoad);
                //     resAfter = await backend.getSchoolSubjectAppointmentsForWeek(weekAfter, firstLoad);
                // }
                // await checkResponseStatus(200, resBefore);
                // await checkResponseStatus(200, resAfter);
                // const responseBefore = await resBefore.json();
                // const responseAfter = await resAfter.json();
                // appointments = responseBefore.weekSchoolSubjectAppointments.concat(appointments.concat(responseAfter.weekSchoolSubjectAppointments));
                // await setTimeslotsInAppointments(appointments);
            }
            const updatedAppointments = await Promise.all(appointments.map(async (appointment) => {
                await dispatch('updateDisplayTeacherInfo',appointment)
                return appointment;
              }));
            commit('handleAppointmentsByWeek', updatedAppointments);
            commit('setAppointmentsLoading', false);
            return updatedAppointments;

        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getAppointmentsByRoomAndWeek({commit, dispatch, getters, rootGetters}, { roomId = '', date = new Date(), fetch = false, }) {
        try {
            // Todo make extra store action for week pageination
            const setTimeslotsInAppointments = async (appointments) => {
                if(rootGetters['auth/accountRole'] === 'teacher') {
                    await Promise.all(appointments.map(async (appointment) => {
                        if ((appointment.timeslot.start && typeof appointment.timeslot.start !== 'object')
                            && (appointment.timeslot.end && typeof appointment.timeslot.end !== 'object')) {
                            appointment.timeslot.start = new Date(appointment.timeslot.start);
                            appointment.timeslot.end = new Date(appointment.timeslot.end);
                        }
                    }));
                }
            }

            commit('setAppointmentsLoading',true);
            const getAppointmentsInWeekCount = (appointments, week)=>{
                if(appointments){
                    const appInWeek = appointments.filter(appointment => appointment.weekId === week);
                    return appInWeek.length
                }
                return 0;
            }
            const weekId = `${date.getWeek()}-${date.getFullYear()}`;

            // i didnt find any efficient way to handle problem with multiple rooms in one appointment, 
            //so if there are any they will need to be pulled out of backend every time for now
            if (!( getAppointmentsInWeekCount(getters.appointmentsByRooms[roomId], weekId)> 0 && !fetch) ) {

                const firstLoad = false;
                const res = await backend.getSchoolSubjectAppointmentsForRoomAndWeek(roomId, weekId, firstLoad);
                await checkResponseStatus(200, res);
                const response = await res.json();
                let { weekSchoolSubjectAppointments: appointments } = response;
                await setTimeslotsInAppointments(appointments);


                const updatedAppointments = await Promise.all(appointments.map(async (appointment) => {
                    await dispatch('updateDisplayTeacherInfo',appointment)
                    return appointment;
                }));
                commit('handleAppointmentsByRoomAndWeek', updatedAppointments);
                commit('setAppointmentsLoading',false);

                return updatedAppointments;
            }else{
                 //get list of appointments from given week that is already in state to return
                 const appointments = JSON.parse(JSON.stringify(getters.appointmentsByRooms[roomId]));
                 await setTimeslotsInAppointments(appointments);
                commit('setAppointmentsLoading',false);

                 return appointments;
            }

           

        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getAppointmentsForTeacherByWeek({commit, dispatch, getters, rootGetters}, { teacherId = '', date = new Date(), fetch = false, }) {
        try {
            // Todo make extra store action for week pageination
            const setTimeslotsInAppointments = async (appointments) => {
                if(rootGetters['auth/accountRole'] === 'teacher') {
                    await Promise.all(appointments.map(async (appointment) => {
                        if ((appointment.timeslot.start && typeof appointment.timeslot.start !== 'object')
                            && (appointment.timeslot.end && typeof appointment.timeslot.end !== 'object')) {
                            appointment.timeslot.start = new Date(appointment.timeslot.start);
                            appointment.timeslot.end = new Date(appointment.timeslot.end);
                        }
                    }));
                }
            }

            commit('setAppointmentsLoading',true);
            // i didnt find any efficient way to handle problem with multiple rooms in one appointment, 
            //so if there are any they will need to be pulled out of backend every time for now
            const weekId = `${date.getWeek()}-${date.getFullYear()}`;
            const getAppointmentsInWeekCount = (appointments, week)=>{
                if(appointments){
                    const appInWeek = appointments.filter(appointment => appointment.weekId === week);
                    return appInWeek.length
                }
                return 0;
            }
            if (!( getAppointmentsInWeekCount(getters.appointmentsByTeacherId[teacherId], weekId)> 0 && !fetch) ) {
                const firstLoad = false;
                const res = await backend.getSchoolSubjectAppointmentsForTeacherAndWeek(teacherId, weekId, firstLoad);
                await checkResponseStatus(200, res);
                const response = await res.json();
                let { weekSchoolSubjectAppointments: appointments } = response;
                await setTimeslotsInAppointments(appointments);


                const updatedAppointments = await Promise.all(appointments.map(async (appointment) => {
                    await dispatch('updateDisplayTeacherInfo',appointment)
                    return appointment;
                }));

                commit('handleAppointmentsByTeacherAndWeek', updatedAppointments);
                commit('setAppointmentsLoading',false);

                return updatedAppointments;
            }else{
                 //get list of appointments from given week that is already in state to return
                 const appointments = JSON.parse(JSON.stringify(getters.appointmentsByTeacherId[teacherId]));
                 await setTimeslotsInAppointments(appointments);
                commit('setAppointmentsLoading',false);

                 return appointments;
            }

           

        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getMultipleAppointments({commit, dispatch, rootGetters}, ids) {
        try {
            if(Array.isArray(ids)) {
                let concatIds = ids[0];
                for(let i = 1; i < ids.length; i++) {
                    concatIds = concatIds + ";" + ids[i]

                }
                const res = await backend.getMultipleSchoolSubjectAppointments(concatIds);
                await checkResponseStatus(200, res);
                const appointments = await res.json();
                const updatedAppointments = await Promise.all(appointments.map(async (appointment) => {
                    await dispatch('updateDisplayTeacherInfo',appointment)
                    return appointment;
                  }));
                commit('addOrUpdateAppointments', updatedAppointments, rootGetters['auth/accountRole'] === 'teacher');
                return appointments;
            } else {
                console.error("ids is not an array");
            }
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getAppointment({commit, dispatch, rootGetters}, id) {
        try {
            const res = await backend.getSchoolSubjectAppointment(id);
            await checkResponseStatus(200, res);
            const appointment = await res.json();
            if(rootGetters['auth/accountRole'] === 'teacher') {
                if ((appointment.timeslot.start && typeof appointment.timeslot.start !== 'object')
                    && (appointment.timeslot.end && typeof appointment.timeslot.end !== 'object')) {
                    appointment.timeslot.start = new Date(appointment.timeslot.start);
                    appointment.timeslot.end = new Date(appointment.timeslot.end);
                }
            }
            const updatedAppointment = await dispatch('updateDisplayTeacherInfo',appointment);
            commit('addOrUpdateAppointment', updatedAppointment);
            return updatedAppointment;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getAppointmentsParent({commit, dispatch, getters}, params) {
        try {
            if (getters.appointments.length > 0) {
                return getters.appointments;
            }
            const res = await backend.getSchoolSubjectAppointmentsParent(params);
            await checkResponseStatus(200, res);
            const appointments = await res.json();
            // const updatedAppointments = await Promise.all(appointments.map(async (appointment) => {
            //     await dispatch('updateDisplayTeacherInfo',appointment)
            //     return appointment;
            //   }));
            // commit('setAppointments', JSON.parse(JSON.stringify(updatedAppointments)));
            return appointments;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postSubstitutionsInAppointments({ dispatch, getters, rootGetters }, params ) {
        if (Array.isArray(params.appointmentIds)) {
            const sickTeachers = [];
            const appointmentIds = params.appointmentIds;
            const formatDateInTime = (date) => {
                const formatter = new Intl.DateTimeFormat('en-US', {
                    timeZone: 'Europe/Berlin',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false, // Use 24-hour format
                  });
            return formatter.format(new Date(date));
            };
            const formatDate = (date) => {
                return new Date(date).toLocaleDateString('de-DE', {
                                                         month: '2-digit',
                                                         day: '2-digit',
                                                         year: '2-digit'
                                                     });
             };
            const appointmentsPromise = appointmentIds.reduce(async (previousPromise, appointmentId) => {
                const previous = await previousPromise;
                const currentApp = getters.appointmentsById[appointmentId];
                let currentTeacher = params.currentTeacher;
                let status;
            
                try {
                    if (rootGetters['auth/accountRole'] === 'teacher') {
                        currentTeacher = currentApp.teachers.find((teacher) => teacher._id === rootGetters['teachers/meTeacher']._id);
                        sickTeachers.push(currentTeacher._id);
                    }
                    const sickTeacher = currentTeacher ? currentTeacher._id : currentApp.teachers[0]._id;
                    const res = await backend.postSubstitution({
                        appointment: appointmentId,
                        sickTeacher,
                    });
                    await checkResponseStatus(201, res);
                   
                    
                    const fullTeacher = rootGetters['teachers/teachersById'][sickTeacher];
                    const timeslot = (rootGetters['auth/accountRole'] === 'teacher') ? currentApp.timeslot : rootGetters['timeslots/timeslotsById'][currentApp.timeslot];
                    const fullGroup = rootGetters['groups/groupsById'][currentApp.group];
                    const hourStart = formatDateInTime(timeslot.start);
                    const hourEnd = formatDateInTime(timeslot.end);
                    let message = '';
                    message = `Die Lehrkraft ${fullTeacher.name} ${fullTeacher.lastName} wurde am ${formatDate(currentApp.day)} in ${fullGroup.name} von ${hourStart} bis ${hourEnd} abwesend gemeldet.`
                    let teacherAccountId = rootGetters['auth/accountRole'] === 'maintainer' ? fullTeacher.account : null;
                    const check = await backend.postSubstitutionNotificationMaintainer({message, teacherAccountId});
                    if(params.notifyPupilAndParents){
                        const notify = await backend.postSubstitutionNotificationPupilAndParent({message, group:fullGroup._id});
                    }
                    sickTeachers.push(sickTeacher);
                    status = res.status;
                } catch (err) {
                    console.error(err);
                    status = err?.response?.status;
                }
            
                previous.push({ _id: appointmentId, status });
                return previous;
            }, Promise.resolve([]));
            await appointmentsPromise;
            // notify relevant teachers if wanted
            if (params.notify) {
                const uniqueSickTeachers = [...new Set(sickTeachers)];
                uniqueSickTeachers.reduce(async (previousPromise, teacher) => {
                    const previous = await previousPromise;
                    const res = await backend.postSubstitutionNotification({
                        appointments: appointmentIds,
                        sickTeacher: teacher,
                    });
            
                    previous.push({ teacher, status: res.status });
                    return previous;
                }, Promise.resolve([]));
            }
            // update said appointments
            dispatch('getMultipleAppointments', appointmentIds);
        }
    },

    async undoSubstitutionsInAppointments({ dispatch, getters, rootGetters }, params) {
        const appointmentIds = params.appointmentIds;
        const teacherId = params.teacherId ? params.teacherId : null;
        if (Array.isArray(appointmentIds)) {
            await appointmentIds.reduce(async (previousPromise, appointment) => {
                const previous = await previousPromise;
                let removeId = getters.appointmentsById[appointment].substitutions[0]._id;
                if(rootGetters['auth/accountRole'] === 'teacher' && teacherId){
                    const substitution = getters.appointmentsById[appointment].substitutions.filter(sub => sub.sickTeacher._id === teacherId);
                    removeId = substitution[0]._id
                }
                if(removeId){
                    let status;
                    try {
                        const res = await backend.deleteSubstitution(removeId);
                        await checkResponseStatus(204, res);
                        status = res.status;
                    } catch (err) {
                        console.error(err);
                        status = err?.response?.status;
                    }
                    previous.push({ _id: removeId, status });
                    return previous;
                }
            }, Promise.resolve([]));
            // update said appointments
            dispatch('getMultipleAppointments', appointmentIds);
        }
    },

    async createTeacherUploadViaFileId({commit, dispatch}, params) {
        try {
            const appointmentId = params.appointmentId;
            delete params.appointmentId;
            const res = await backend.postTeacherUploadFromFileWidget(appointmentId, params);
            await checkResponseStatus(201, res);
            return true;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async postPupilUploadViaFileId({commit, dispatch}, params) {
        try {
            const appointmentId = params.appointmentId;
            delete params.appointmentId;
            const res = await backend.postPupilUploadFromFileWidget(appointmentId, params);
            await checkResponseStatus(201, res);
            return true;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async setAppointmentNoteSeen({ commit, dispatch, getters }, appointmentId) {
        try {
            const res = await backend.patchAppointmentNoteSeen(appointmentId);
            await checkResponseStatus(201, res);
            commit('removePlanBadgeEventByAppointment', appointmentId);
            const appointment = getters.appointments.find(el => el._id === appointmentId);
            const updatedAppointment = { ...appointment, noteSeenByMe: true };
            commit('patchAppointment', updatedAppointment);
            return true;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async patchAppointmentUploadSeenBy({ commit, dispatch, getters}, { uploadId, accountId, role }) {
        try {
            const res = await backend.patchAppointmentUploadSeenBy(uploadId, { userToSetSeen: accountId });
            await checkResponseStatus(201, res);
            commit('removePlanBadgeEventByUpload', { uploadId, role });
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getSchoolSubjectAppointmentUploadThumbnail({ commit, getters }, { file, appointmentId, uploadId}) {
        const foundThumbnail = getters.getThumbnailFromUpload(uploadId);
        if (foundThumbnail) {
            return foundThumbnail;
        }
        try {
            const res = await backend.getSchoolSubjectAppointmentUploadThumbnail(file._id, appointmentId);
            const blob = await res.blob();
            if (blob.type !== 'text/plain; charset=utf-8') {
                commit('addThumbnail', {uploadId, blob});
            }
            return blob;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async getTeacherUpload({ commit, dispatch }, { appointmentId, fileId }) {
        try {
            const res = await backend.getTeacherUpload(appointmentId, fileId);
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },

    async patchSchoolSubjectAppointment({ commit, dispatch }, { data }) {
        try {
            const res = await backend.patchSchoolSubjectAppointment(data._id, data);
            return res;
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
    async getTeacherAppointmentsForDates({ commit, dispatch }, params) {
        try {
            const res = await backend.getTeacherAppointmentsForDates(params.teacherId, params.dateStart, params.dateEnd);
            await checkResponseStatus(200, res);
            return await res.json();
        } catch (err) {
            console.error(err);
            return err?.response?.status;
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
